import { useHistory } from 'react-router'
import { useTranslation, useTypedSelector } from '../../../../hooks'
import NavigationArrowLink from '../../../reusable/buttons/NavigationArrowLink'
import {
    Container,
    BackToHomeScreen,
    PolicyContainer,
    Heading,
    Paragraph,
    NavigationLink,
    NavigationLinkContainer,
    NavigationLinkDivider,
    Li,
} from './styled'

const WhistleblowerPolicyPage: React.FC = () => {
    const {
        customWbPolicyName,
        companyName,
        organizationalCode,
        policyContactLines,
    } = useTypedSelector((state) => state.whistleblower)

    const history = useHistory()

    const translation = useTranslation()

    return (
        <>
            <BackToHomeScreen>
                <NavigationArrowLink
                    onClickCallback={() =>
                        history.push(
                            `/organization/${organizationalCode}/whistleblower`
                        )
                    }
                    linkText={
                        translation.whistleblowerPolicyPage.backToHomescreen
                    }
                />
            </BackToHomeScreen>
            <Container>
                <NavigationLinkContainer>
                    <NavigationLink active={true}>{companyName}</NavigationLink>
                    <NavigationLinkDivider />
                    <NavigationLink active={false}>
                        {
                            translation.whistleblowerPolicyPage
                                .whistleblowerPolicyHeading
                        }
                    </NavigationLink>
                </NavigationLinkContainer>
                <PolicyContainer>
                    <Heading>
                        {companyName}
                        {
                            translation.whistleblowerPolicyPage
                                .whistleblowerPolicy
                        }
                    </Heading>
                    <Paragraph>
                        {
                            translation.whistleblowerPolicyPage
                                .thePurposeOfThisWhistleblower
                        }{' '}
                        {companyName}
                        {
                            translation.whistleblowerPolicyPage
                                .whistleblowerSchemeWorks
                        }{' '}
                        {companyName}
                        {'. '}
                        {translation.whistleblowerPolicyPage.aGoodUnderstanding}
                    </Paragraph>
                    <Heading>
                        {translation.whistleblowerPolicyPage.introductionTo}{' '}
                        {companyName}
                        {
                            translation.whistleblowerPolicyPage
                                .whistleblowerScheme
                        }
                    </Heading>
                    <Paragraph>
                        {companyName}{' '}
                        {
                            translation.whistleblowerPolicyPage
                                .isAResponsibleCompanyWith
                        }
                    </Paragraph>
                    <Paragraph>
                        {companyName}
                        {
                            translation.whistleblowerPolicyPage
                                .whistleblowerSchemeEnsuresProtection
                        }{' '}
                        {companyName}{' '}
                        {translation.whistleblowerPolicyPage.bothInThePast}
                    </Paragraph>
                    <Heading>
                        {
                            translation.whistleblowerPolicyPage
                                .whoCanUseThisWhistleblowingScheme
                        }
                    </Heading>
                    <Paragraph>
                        {companyName}
                        {
                            translation.whistleblowerPolicyPage
                                .whistleblowerSchemeCanBeUsedByAnyone
                        }{' '}
                        {companyName}
                        {
                            translation.whistleblowerPolicyPage
                                .whichTheyHaveAcquired
                        }{' '}
                        {companyName}.
                    </Paragraph>
                    <Paragraph>
                        {' '}
                        {
                            translation.whistleblowerPolicyPage
                                .theCategoriesOfPeopleWho
                        }
                        <ul>
                            <Li>
                                {translation.whistleblowerPolicyPage.employees}
                            </Li>
                            <Li>
                                {
                                    translation.whistleblowerPolicyPage
                                        .jobApplicants
                                }
                            </Li>
                            <Li>
                                {
                                    translation.whistleblowerPolicyPage
                                        .paidOrUnpaidTrainees
                                }
                            </Li>
                            <Li>
                                {translation.whistleblowerPolicyPage.volunteers}
                            </Li>
                            <Li>
                                {
                                    translation.whistleblowerPolicyPage
                                        .selfEmployedPersons
                                }
                            </Li>
                            <Li>
                                {
                                    translation.whistleblowerPolicyPage
                                        .shareholders
                                }
                            </Li>
                            <Li>
                                {
                                    translation.whistleblowerPolicyPage
                                        .jobApplicants
                                }
                            </Li>
                            <Li>
                                {
                                    translation.whistleblowerPolicyPage
                                        .membersOfTheManagement
                                }{' '}
                                {companyName}
                            </Li>
                            <Li>
                                {
                                    translation.whistleblowerPolicyPage
                                        .personsWorkingUnder
                                }{' '}
                                {companyName}
                            </Li>
                        </ul>
                    </Paragraph>
                    <Paragraph>
                        {
                            translation.whistleblowerPolicyPage
                                .personsFallingUnderTheseCategories
                        }{' '}
                        {companyName}{' '}
                        {translation.whistleblowerPolicyPage.hasCeased}
                    </Paragraph>
                    <Heading>
                        {translation.whistleblowerPolicyPage.whatCanYouReport}
                    </Heading>
                    <Paragraph>
                        {
                            translation.whistleblowerPolicyPage
                                .theWhistleblowerSchemeCanBeUsed
                        }{' '}
                        {companyName}
                        {'. '}
                        {
                            translation.whistleblowerPolicyPage
                                .alsoInTheCaseOfRasonable
                        }
                    </Paragraph>
                    <Paragraph>
                        {translation.whistleblowerPolicyPage.theViolationsWhich}
                    </Paragraph>
                    <Paragraph>
                        {
                            translation.whistleblowerPolicyPage
                                .activitesThatCanBeReported
                        }
                    </Paragraph>
                    <Paragraph>
                        {
                            translation.whistleblowerPolicyPage
                                .ifYouAreInDoubtAbout
                        }
                    </Paragraph>
                    <Heading>
                        {
                            translation.whistleblowerPolicyPage
                                .protectionOfWhistleblowers
                        }
                    </Heading>
                    <Paragraph>
                        {
                            translation.whistleblowerPolicyPage
                                .anyoneWhoReportsMatters
                        }
                    </Paragraph>
                    <Paragraph>
                        {
                            translation.whistleblowerPolicyPage
                                .ifAWhistleblowerSuffers
                        }{' '}
                        {companyName}{' '}
                        {
                            translation.whistleblowerPolicyPage
                                .heOrSheWillBeCompensated
                        }{' '}
                        {companyName}{' '}
                        {
                            translation.whistleblowerPolicyPage
                                .mayAlsoBeSubjectToOtherSanctions
                        }
                    </Paragraph>

                    <Heading>
                        {translation.whistleblowerPolicyPage.howToReport}
                    </Heading>
                    <Paragraph>
                        {
                            translation.whistleblowerPolicyPage
                                .reportsThatLieWithin
                        }
                    </Paragraph>
                    <Paragraph>
                        {policyContactLines ? (
                            <ul>
                                {policyContactLines.map((contact: any) => (
                                    <Li
                                        key={contact.id}
                                        style={{ paddingBottom: '1rem' }}
                                    >
                                        {contact.first_name} {contact.last_name}
                                        {' - '}
                                        {contact.contact_information.position}
                                    </Li>
                                ))}
                            </ul>
                        ) : (
                            <p>Line of contacts have not been added</p>
                        )}

                        {
                            translation.whistleblowerPolicyPage
                                .onlyThesePersonsAreAuthorised
                        }
                    </Paragraph>
                    <Heading>
                        {
                            translation.whistleblowerPolicyPage
                                .howAreTheReportsProcessed
                        }
                    </Heading>
                    <Paragraph>
                        {
                            translation.whistleblowerPolicyPage
                                .youWilRecieveAnAcknowledgement
                        }
                    </Paragraph>
                    <Heading>
                        {
                            translation.whistleblowerPolicyPage
                                .subsequentClarificationAndCollection
                        }
                    </Heading>
                    <Paragraph>
                        {
                            translation.whistleblowerPolicyPage
                                .whenYouCreateAReport
                        }
                    </Paragraph>
                    <Heading>
                        {
                            translation.whistleblowerPolicyPage
                                .confidentialityAndITSecurity
                        }
                    </Heading>
                    <Paragraph>
                        {
                            translation.whistleblowerPolicyPage
                                .aReportCanBeMadeEither
                        }
                    </Paragraph>
                    <Paragraph>
                        {
                            translation.whistleblowerPolicyPage
                                .theWhistleblowerReportingChannel
                        }
                    </Paragraph>
                    <Heading>
                        {
                            translation.whistleblowerPolicyPage
                                .noticeOfPersonConcerned
                        }
                    </Heading>
                    <Paragraph>
                        {
                            translation.whistleblowerPolicyPage
                                .theRecipientOfTheReport
                        }
                    </Paragraph>
                    <Paragraph>
                        {
                            translation.whistleblowerPolicyPage
                                .noInformationEitherDirect
                        }
                    </Paragraph>
                    <Heading>
                        {translation.whistleblowerPolicyPage.questions}
                    </Heading>
                    <Paragraph>
                        {translation.whistleblowerPolicyPage.allQuestions}{' '}
                        {customWbPolicyName !== 'klaravik'
                            ? companyName
                            : 'Emma Rask - emma.rask@klaravik.se'}
                        .
                    </Paragraph>
                </PolicyContainer>
            </Container>
        </>
    )
}

export default WhistleblowerPolicyPage
