/*
 * Example HR-ON
 * We generate a authorization_code that is saved on our DB
 * We redirect the user to HR-ON with the authorization_code
 * We also resolve the integration_type which is either registration or login
 * Later we can use the integration_type to determine where to redirect the user
 * We can use the authorization_code later to decrypt a payload from HR-ON
 */

import config from '../../../config'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { useParams, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import Spinner from '../../layout/spinner/Spinner'
import { useHistory } from 'react-router'

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 8.5rem);
`

type IntegrationName = 'hr-on' | 'ziik'

const InitIntegrationPage: React.FC = () => {
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)

    const history = useHistory()

    // Access individual query parameters
    const integrationType = queryParams.get('integrationType')
    const redirectUrl = queryParams.get('redirectUrl')
    const clientId = queryParams.get('clientId')
    const employeeId = queryParams.get('employeeId')

    const { uuid } = useParams<{
        uuid: string
    }>()

    const [integration, setIntegration] = useState<null | IntegrationName>(null)

    const { apiUrl } = config

    useEffect(() => {
        const getIntegrationPartner = async () => {
            const res = await axios.get(`${apiUrl}/v1/integrations/${uuid}`)

            const identifierName = res.data.data.identifier_name

            setIntegration(identifierName)
        }

        getIntegrationPartner()
    }, [uuid, apiUrl])

    const createZiikIntegration = async () => {
        if (!redirectUrl || !integrationType) {
            return
        }

        interface WalorIntegrationPayload {
            integration_type: 'register' | 'login'
            redirect_url: string
            temp?: string
        }

        const payload: WalorIntegrationPayload = {
            integration_type: integrationType as 'register' | 'login',
            redirect_url: redirectUrl,
        }

        if (integrationType === 'register') {
            const integrationData = queryParams.get('integrationData')

            if (!integrationData) {
                return
            }

            payload.temp = integrationData
        }

        const res = await axios.post(
            `${apiUrl}/v1/integrations/${uuid}`,
            payload
        )

        // We can redirect right back to our login page WITH integrationID
        history.push(
            `/${integrationType}/integrations?integrationId=${res.data.data.id}`
        )
    }

    useEffect(() => {
        if (!integration) return

        const createHrOnIntegration = async () => {
            const payload = {
                integration_type: integrationType,
                client_id: clientId,
                employee_id: employeeId,
                redirect_url: redirectUrl,
            }

            const res = await axios.post(
                `${apiUrl}/v1/integrations/${uuid}`,
                payload
            )

            const authorizationCode = res.data.data.authorization_code

            const urlWithAuthCode = `${redirectUrl}?authorizationCode=${authorizationCode}`

            window.location.replace(urlWithAuthCode)
        }

        if (integration === 'hr-on') {
            createHrOnIntegration()
        }

        if (integration === 'ziik') {
            createZiikIntegration()
        }
        // eslint-disable-next-line
    }, [
        apiUrl,
        integration,
        integrationType,
        redirectUrl,
        uuid,
        clientId,
        employeeId,
    ])

    return (
        <Container>
            <Spinner />
        </Container>
    )
}

export default InitIntegrationPage
