import { useEffect, useState } from 'react'
import { useActions, useTypedSelector } from '../../../hooks'
import IntegrationModal, { IntegrationModalProps } from './IntegrationModal'

const IntegrationModalResolver: React.FC = () => {
    const [isActive, setIsActive] = useState(false)
    const { approveIntegration } = useActions()

    const [modalProps, setModalProps] = useState<IntegrationModalProps>({})

    const [organizationalCode, setOrganizationalCode] = useState<null | string>(
        null
    )

    const { activeIntegration } = useTypedSelector((state) => state.integration)

    const { getIdentity } = useTypedSelector((state) => state.identity)

    const [ziikData, setZiikData] = useState<null | {
        organizationalCode: string
        organizationName: string
        caseworkers: { name: string; position: string }[]
    }>()

    useEffect(() => {
        if (activeIntegration && !activeIntegration.is_integration_confirmed) {
            return setIsActive(true)
        }
    }, [activeIntegration])

    useEffect(() => {
        if (!isActive || !activeIntegration) {
            return
        }

        if (activeIntegration.partner.identifier_name === 'ziik') {
            const organizationalCode =
                activeIntegration.organization.data.organizational_code
            const organizationName = activeIntegration.organization.data.name
            const caseworkers =
                activeIntegration.organization.data.contact_lines.data.map(
                    (caseworker) => ({
                        name: `${caseworker.first_name} ${caseworker.last_name}`,
                        position: caseworker.contact_information
                            .position as string,
                    })
                )

            setZiikData({ organizationalCode, organizationName, caseworkers })
        }
    }, [isActive, activeIntegration])

    useEffect(() => {
        if (!getIdentity) {
            return
        }

        setOrganizationalCode(getIdentity.organization.data.organizational_code)
    }, [getIdentity])

    useEffect(() => {
        if (!activeIntegration || !organizationalCode) {
            return
        }

        if (activeIntegration?.partner.identifier_name === 'hr-on') {
            setModalProps({
                approveCB: async () => {
                    const success = await approveIntegration(
                        activeIntegration.uuid as string
                    )

                    if (!success) {
                        return alert(
                            'Failed to make the connection to hr-on. Try to go back to hr-on and select "Use existing account" If it still does not work, please contact support.'
                        )
                    }

                    const url = `${activeIntegration.redirect_url}?final=true`

                    window.open(url)
                },
                cancelCB: () => {
                    const url = `${activeIntegration.redirect_url}?final=true`

                    window.open(url)
                },
            })
        }

        if (activeIntegration?.partner.identifier_name === 'ziik') {
            if (!ziikData) {
                return
            }

            setModalProps({
                approveCB: () => {
                    const url = `${
                        activeIntegration.redirect_url
                    }&data=${encodeURIComponent(JSON.stringify(ziikData))}`

                    window.open(url)

                    setIsActive(false)
                },
            })
        }
        // eslint-disable-next-line
    }, [activeIntegration, organizationalCode, ziikData])

    return <>{isActive && <IntegrationModal {...modalProps} />}</>
}

export default IntegrationModalResolver
