const fi = {
    registerPage: {
        accountType: 'Tilin tyyppi',
        noCreditCardRequired: 'Luottokorttia ei tarvita, aloita',
        freeTrial: 'ilmainen kokeilusi',
        today: 'tänään',
        firstname: 'Etunimi',
        phoneNumber: 'Puhelinnumero',
        software: 'Ohjelmisto',
        softwarePlusScreening: 'Ohjelmisto + seulonta',
        easyAndSecure: 'Helppo ja turvallinen',
        chargedYearly: 'Veloitetaan vuosittain',
        secureWhistleblowerPlatform: 'Turvallinen ilmiantajafoorumi',
        tailoredWhistleblowerPolicy: 'Räätälöity ilmiantajapolitiikka',
        reportManagement: 'Raporttien hallinta',
        followUpCommunication: 'Seurantaviestintä',
        emailNotifications: 'Sähköposti-ilmoitukset',
        employeeGuides: 'Työntekijöiden oppaat',
        areYouLookingFo: 'Etsitkö kumppaniohjelmaamme?',
        partnerprogrammodal: 'Kumppanuusohjelma',
        iHaveAgreedToWalor: 'Olen lukenut ja hyväksynyt Walorin',
        termsAndConditions: 'Ehdot ja edellytykset',
        and: 'ja',
        privacyAndCookiePolicy: 'Tietosuoja- ja evästekäytäntö',
        dataProcessingAgreement: 'Tietojenkäsittelysopimus',
        ifYouAreLookingFor:
            'Jos etsit kumppaniohjelmaa, ota meihin yhteyttä osoitteessa',
        thanksCheckYour: 'Valmista! Tarkista sähköpostisi',
        rememberToCheckYourSpam:
            'Etkö saanut sähköpostia? Tarkista roskapostikansiosi tai ota meihin yhteyttä osoitteessa',
        areYouAMemberOf: 'Oletko ___ jäsen',
    },
    onboardingPage: {
        hi: 'Hei',
        welcomeToWalor: ', tervetuloa Waloriin!',
        youAreAlmostDone: 'Olet melkein valmis uuden ratkaisun käyttöönotossa',
        insertCompanyInformation: 'Yrityksen tiedot',
        subcreateYourCompanyInformation:
            'Lisää yrityksen osoite ja työntekijöiden lukumäärä.',
        generateWhistleblowerPolicy: 'Ilmoituskanavan politiikka',
        readyToGo: 'Aloitetaan',
        pleaseFillinThisInformation: 'Syötä yrityksesi tiedot',
        nextStep: 'Seuraava vaihe',
        name: 'Yrityksen nimi',
        createYourLineOfContact: 'Luo yhteyshenkilösi',
        skipThisStep: 'Ohita tämä vaihe',
        subcreateYourLineOfContact:
            'Nämä käyttäjät ovat vastuussa organisaatiosi ilmoitusraporttien käsittelystä.',
        subgenerateyourwbpolicy:
            'Tässä kuvataan ilmoittajaratkaisunne oikeudellinen kehys.',
        clickHere: 'Klikkaa tästä',
        complete: 'Täydellinen',
        yourWhistleblowerpolicyIsBeingGenerated:
            'Ilmoittajaraporttiasi luodaan parhaillaan.',
        goodWork: 'Onnittelut',
        yourPolicyHasBeen: 'Ilmoittajaraporttisi on luotu!',
        allSetEasy: 'Kaikki valmiina! Helppoa, eikö?',
        letsGetYouStarted: 'Aloitetaan heti',
        takeMeToTheDashboard: 'Vie minut työpöydälle',
    },
    onboardingFlow: {
        enterCompanyNumber: 'Yrityksen numero',
        enterCompanyName: 'Yrityksen nimi',
        enterPhone: '12345678',
        enterAddress: 'Osoite',
        enterCity: 'Kaupunki',
        enterZipCode: '1234',
    },
    loginFlow: {
        login: 'Kirjaudu sisään',
        forgotPassword: 'Unohditko salasanan?',
        underPageForgotPassword: 'Unohditko salasanan?',
        underPageWriteYourEmail:
            'Kirjoita sähköpostiosoitteesi alle luodaksesi uuden salasanan',
        howDoYouWant: 'Miten haluat saada turvakoodisi?',
        buttonEmail: 'Sähköposti',
        buttonSMS: 'Tekstiviesti',
        or: 'tai',
        backToLogin: 'Takaisin kirjautumiseen',
        enterThe6digit: 'Syötä 6-numeroinen koodi',
        yourCodeWillArrive: 'Koodisi saapuu muutamassa sekunnissa',
        didYouNotReceive:
            'Etkö saanut koodia? Klikkaa tästä lähettääksesi koodin uudelleen',
    },
    whistleblowerCreateCase: {
        whistleblowerScheme: 'Ilmoittajan raportointikanava',
        createReport: 'Luo raportti',
        startReport: 'Anonyymin ja turvallisen ilmoituksen tekeminen',
        accessExistingReport: 'Pääsy olemassa olevaan raporttiin',
        understandWhistleblowing: 'Ymmärtää ilmiantajaksi ilmoittaminen',
        readMore: 'Työntekijöiden oppaat',
        playvideo: 'Pelaa',
        howThisWorks: 'Miten tämä toimii',
        createAReport: 'Luo raportti',
        createAReportOris:
            'Luo nimetön tai luottamuksellinen raportti. Voit antaa keräämäsi tiedot ja tiedostot. Huomaa, että ilmiantajajärjestelmä on perustettu vapaaehtoiselta pohjalta, eikä se näin ollen kuulu ilmiantajia koskevan lain soveltamisalaan, minkä seurauksena i) ilmoitukset eivät saa sisältää niin sanottuja arkaluonteisia henkilötietoja, mukaan lukien tiedot, joista potilaat voidaan tunnistaa, ja ii) ilmiantajille ei sovelleta ilmiantajia koskevassa laissa säädettyä erityistä suojaa. ORIS Dentistsin työntekijänä takaamme sinulle kuitenkin samanlaisen suojan osana työsuhdettasi. Kun olet lähettänyt raportin, saat ainutlaatuisen ilmiantajan koodin, jonka avulla voit käyttää raporttia uudelleen.)',
        createAReportSimply:
            'Luo raportti nimettömänä tai luottamuksellisesti. Voit antaa kaikki keräämäsi tiedot ja tiedostot. Kun olet lähettänyt raportin, saat yksilöllisen whistleblowercode-koodin, jonka avulla voit käyttää raporttia uudelleen.',
        investigatingthereport: 'Sisäinen tutkinta',
        caseworkerinthecompany:
            'Ilmoituksen käsittelyyn valitsemasi henkilöt saavat sähköpostiinsa ilmoituksen sisäisen tutkinnan aloittamisesta.',
        answeradditionalquestions: 'Vastaa lisäkysymyksiin',
        additionalQuestions:
            'Valitsemillasi sosiaalityöntekijöillä saattaa olla lisäkysymyksiä raporttiisi. Muista syöttää raporttisi silloin tällöin uudelleen sisäisen tutkinnan helpottamiseksi.',
        companiesWhistleblowerPolicy:
            'Whistleblowing- eli Ilmoittajansuojelulaki',
    },
    whistleblowerHowItWorksPage: {
        whistleblowing: 'Whistleblowing yleisesti',
        whatIsWhistleblowing: 'Mitä on Whistleblowing?',
        whistleblowingDefinition:
            'Whistleblowing tarkoittaa sitä, että yksittäinen henkilö raportoi laittomista tai moraalittomista toimista organisaatiossa. Ilmoittaja voi olla joko organisaation jäsen tai organisaatioon liittyvä kolmas osapuoli.',
        whereItsUseful:
            'Valtaosassa tapauksista tapahtuu työhön liittyvässä yhteydessä. Työntekijä saattaa esimerkiksi saada työssään tietoa työnantajansa laittomaksi epäilemästään toiminnasta, josta hän päättää ilmoittaa.',
        whatAreTheTypes: 'Millaisia ilmianto-tapauksia on olemassa?',
        typesOfWhistleblowing:
            'Sisäinen ja ulkoinen ilmoitus voidaan erottaa toisistaan. Sisäinen ilmoitus tarkoittaa, että ilmoittaja tekee ilmoituksen organisaatiossa, johon hän kuuluu tai johon hän on yhteydessä. Tämä tarkoittaa sitä, että ilmoittaja kertoo epäilemästään väärinkäytöksestä vain kyseisen organisaation sisällä. Toisaalta ulkoinen ilmoitus tarkoittaa sitä, että ilmoittaja varoittaa muita kuin organisaatiota itseään kyseisen organisaation toiminnasta. Tällöin tieto välitetään esimerkiksi viranomaisille tai tiedotusvälineille.',
        whatIsTheImportance: 'Mikä on ilmoittajan merkitys?',
        preventionOrReparation:
            'Ensinnäkin ilmoittajalla on olennainen rooli yleiseen etuun kohdistuvien haittojen ehkäisemisessä tai korjaamisessa, sillä organisaatioon kuuluvat tai siihen liittyvät henkilöt ovat usein ensimmäisiä tai ainoita, joilla on tietoa tällaisista haitoista.',
        ethicCulture:
            'Organisaation näkökulmasta ilmoittaminen edistää lisäksi eettistä, laillista, avointa ja osallistavaa toimintakulttuuria.',
        whistleblowerSafety:
            'Ilmoittaja voi myös voittaa syyllisyyden tunteen tai vastuuntunnon sen jälkeen, kun hän on saanut tietoa laittomista tai moraalittomista toimista yrityksessä, johon hän kuuluu tai johon hän liittyy.',
        whistleblowerImportance:
            'Tämän merkityksen vuoksi ilmoittajia suojellaan lailla.',
        whistleblowerScheme: 'Ilmoituskanava ',
        whatIsWhistleblowerScheme: 'Mikä on ilmoituskanavajärjestelmä?',
        whistleblowerIsATool:
            'Ilmoituskanavajärjestelmä on väline, jonka avulla ihmiset voivat tehdä ilmoituksia tietystä organisaatiosta. Järjestelmä ohjaa siten ilmoittajia laittomaksi epäilemästään tai epäeettisestä toiminnasta ilmoittamisen prosessissa. Näin organisaatiot voivat saada tietoa laittomasta tai epäeettisestä toiminnasta, tutkia sitä ja korjata sen.',
        whyHaveAWhistleBlowerScheme:
            'Miksi on olemassa ilmoituskanavajärjestelmä?',
        whistleblowerLaw:
            'Euroopan unionin kiinnitettyä entistä enemmän huomiota ilmiantojen arvoon EU antoi 23. lokakuuta 2019 direktiivin 2019/1937 unionin oikeuden rikkomisesta ilmoittavien henkilöiden suojelusta. Tämä ilmiantajadirektiivi, joka on saatettu osaksi Tanskan lainsäädäntöä, tarjoaa oikeudellisen kehyksen ilmoituskanavajärjestelmille ja suojaa ilmoittajia, jotka ilmoittavat tietoja ilmoituskanavajärjestelmän kautta.',
        whistleblowingSchemeTransparency:
            'Tämä ilmoituskanavajärjestelmä antaa kaikille mahdollisuuden saada äänensä kuuluviin ja käyttää oikeuttaan tulla kuulluksi, mikä on tärkeää avoimuuden ja yhteenkuuluvuuden tunteen kulttuurin luomiseksi. Lisäksi järjestelmä tukee ja kannustaa ottamaan huolenaiheet esille sisäisesti, lähellä ongelman lähdettä. Näin huolenaiheet voidaan korjata varhaisessa vaiheessa ja tehokkaasti.',
        whoCanUseThisWhistleblowerScheme:
            'Kuka voi käyttää ilmoituskanavajärjestelmää?',
        anyoneCanUse:
            'Ilmoituskanavajärjestelmää voi käyttää kuka tahansa, kenellä on julkista tai yksityistä organisaatiota koskevia tietoja, jotka hän on saanut haltuunsa työhönsä liittyvän toiminnan yhteydessä.',
        categoriesOfPeople:
            'Henkilöryhmät, jotka voivat käyttää tätä ilmiantojärjestelmää, ovat laajoja. Ilmoituksia voivat siis tehdä:',
        people1:
            'Työntekijät. Tämä koskee sekä koko- että osa-aikaisia työntekijöitä sekä työntekijöitä, jotka työskentelevät toistaiseksi voimassa olevalla tai määräaikaisella sopimuksella.',
        people2: 'Työnhakijat',
        people3: 'Palkalliset tai palkattomat harjoittelijat',
        people4: 'Vapaaehtoiset',
        people5: 'Itsenäiset ammatinharjoittajat',
        people6: 'Osakkeenomistajat',
        people7: 'Johdon, hallintoneuvoston tai muun hallintoelimen jäsenet',
        people8:
            'Näihin ryhmiin kuuluvat henkilöt voivat käyttää ilmoituskanavajärjestelmää riippumatta siitä, onko heidän työsuhteensa organisaatioon päättynyt, onko se vielä kesken vai onko se vielä alkamatta.',
        noMatterTheRelationship:
            'Näihin ryhmiin kuuluvat henkilöt voivat käyttää ilmoituskanavajärjestelmää riippumatta siitä, onko heidän työsuhteensa organisaatioon päättynyt, onko se vielä kesken vai onko se vielä alkamatta.',
        whatCanBeReported:
            'Mistä asioista voidaan ilmoittaa tämän ilmoituskanavajärjestelmän kautta?',
        seriousViolations:
            'Ilmoituskanavajärjestelmää voidaan käyttää, jos on saatu tietoja vakavista lainrikkomuksista, yrityksistä salata tällaisia rikkomuksia tai muista vakavista asioista, joita organisaatiossa on tapahtunut tai todennäköisesti tapahtuu. Tietoja voidaan ilmoittaa myös silloin, kun on perusteltua epäillä, että tällaisia rikkomuksia tai asioita on tapahtunut.',
        violationsOfLaw:
            'Rikkomukset, joista voidaan ilmoittaa ilmoituskanavajärjestelmän kautta, liittyvät EU-lainsäädännön erityisiin rikkomuksiin tai muihin vakaviin rikoksiin ja asioihin Tanskan lainsäädännön mukaan. Järjestelmän kautta ilmoitettavat toimet liittyvät esimerkiksi korruptioon, ympäristönsuojeluun, kansanterveyteen, yksityisyyteen ja henkilötietoihin tai seksuaaliseen häirintään ja seksuaaliseen hyväksikäyttöön. Nämä ovat vain esimerkkejä. Myös muista vakavista asioista tai lainrikkomuksista voidaan ilmoittaa.',
        whatCantBeReported:
            'Mitä ei voi ilmoittaa tämän ilmoituskanavajärjestelmän kautta?',
        lessSeriousMatters:
            'Tätä ilmoituskanavajärjestelmää ei ole tarkoitettu vähemmän vakavien asioiden tai tavanomaisten työsuhdeasioiden, kuten palkkaan tyytymättömyyden tai vähäisten kollegiaalisten ongelmien, ilmoittamiseen. Lisäksi ei ole tarkoitus tehdä ilmoituksia, joita ei ole tehty vilpittömässä mielessä ja jotka ovat ilmeisen perusteettomia.',
        inCaseOfDoubt:
            'Jos olet epävarma siitä, onko havainto tai epäily vakava ja pitäisikö siitä ilmoittaa vai ei, sinua kehotetaan silti tekemään niin. Kaikkiin tiedusteluihin vastataan ja ne käsitellään.',
        howAreYouProtected:
            'Miten sinua suojellaan tätä järjestelmää käytettäessä?',
        noLiability:
            'Henkilöt, jotka ilmoittavat vakavista asioista vilpittömässä mielessä tämän järjestelmän kautta, nauttivat laajaa suojaa ilman, että he joutuvat vastuuseen siitä, että he ovat rikkoneet luottamuksellisuusvelvollisuuttaan ilmoitettujen tietojen osalta. Myös ilmoittajaa avustavat henkilöt tai hänen lähipiiriinsä kuuluvat henkilöt tai yritykset kuuluvat tämän suojan piiriin.',
        noRetaliation:
            'Tämä suoja sisältää suojan kaikenlaisilta kostotoimilta. Vastatoimet tarkoittavat mitä tahansa toimintaa tai laiminlyöntiä, joka on seurausta ilmoituksesta ja joka aiheuttaa tai saattaa aiheuttaa perusteetonta haittaa ilmoittajalle. Tällaisia toimia voi olla monenlaisia, kuten esimerkiksi irtisanominen, työpaikan vaihtaminen, palkan alentaminen tai henkilökohtaisen tai ammatillisen maineen vahingoittaminen. Jos ilmoittaja tai suojattu henkilö joutuu vastatoimien kohteeksi, hänelle maksetaan korvausta. Organisaatio, joka ryhtyy vastatoimiin, voi joutua myös muiden seuraamusten kohteeksi.',
        howDoesTheProcessWork: 'Miten raportointiprosessi toimii?',
        howDoIReport: 'Miten ilmiannan?',
        reportingChannel:
            'Voit luoda raportin sen organisaation digitaalisen raportointikanavan kautta, josta aiot raportoida.',
        whatIfIncomplete: 'Entä jos raporttini on epätäydellinen?',
        accessAndExpand:
            'Raporttiisi on aina mahdollista tutustua ja täydentää sitä toimittamalla lisätietoja tai todisteita. Asiaa käsittelevät henkilöt saattavat myös pyytää lisäkysymyksiä tai -asiakirjoja, jotka voivat olla merkityksellisiä raportin käsittelyn ja tutkinnan kannalta. Vastausten tai asiakirjojen toimittaminen myöhemmin riippuu kuitenkin yksinomaan halukkuudestasi tehdä niin. Siihen ei ole velvollisuutta.',
        whoIsProccessing: 'Kuka käsittelee raporttini?',
        thirdParty:
            'Ilmoituksesi käsittelee yrityksen whistleblowing-yksikön valittu henkilö tai valitut henkilöt. Ainoastaan näillä henkilöillä on valtuudet suorittaa tutkinta. Asiaa ei missään tapauksessa anneta sellaisen henkilön tehtäväksi, joka on osallisena ilmoitetussa toiminnassa tai jonka kanssa voi olla eturistiriita.',
        howIsProccessed: 'Miten raporttini käsitellään?',
        investigations:
            'Saat seitsemän päivän kuluessa ilmoituksen raportin vastaanottamisesta. Tämän jälkeen raportti tarkastetaan alustavasti. Jos raportti osoittautuu huomattavan perusteettomaksi, se hylätään. Saat tästä ilmoituksen mahdollisimman pian. Jos alustavassa tutkimuksessa kuitenkin todetaan, että ilmoitusta ei voida pitää erityisen perusteettomana, sitä tutkitaan edelleen. Tapauksesi käsitellään sen jälkeen sisäisesti. Tällä sisäisellä tutkimuksella voi olla seurauksia asianosaiselle tai asianosaisille. Jos tapaus on erityisen vakava, se voidaan myös siirtää poliisille lisätutkimuksia varten. Kun kaikki tutkimukset on suoritettu ja mahdolliset seuraamukset määrätty, tapaus lopetetaan. Sen jälkeen se poistetaan järjestelmästä.',
        whatIfLooseCode: 'Mitä jos menetän ilmoittajakoodini?',
        looseAccess:
            'Jos menetät ilmoittajakoodin, et valitettavasti pääse enää käsiksi ilmoitukseesi. Tämä on ainoa mahdollisuus taata turvallinen ja nimetön raportti. Raporttisi tarkastetaan ja tutkitaan kuitenkin edelleen.',
        unableToUptade:
            'Et voi päivittää raporttiasi tai vastata raporttia koskeviin kysymyksiin. Tätä varten on tehtävä uusi ilmoitus, joka on toimitettava tämän ilmoittajajärjestelmän kautta.',
        howLongDoesItTake: 'Kuinka kauan raporttini käsittely kestää?',
        whithin3Months:
            'Ilmoituksesi käsitellään ja tutkitaan mahdollisimman pian. Tämän prosessin aikana saat palautetta mahdollisista päivityksistä tai tuloksista. Kokonaiskesto riippuu tapauksestasi, mutta saat viimeistään kolmen kuukauden kuluessa raportoinnista tietoa suunnitelluista tai toteutetuista toimista.',
        willIRemainSecret: 'Pysyykö henkilöllisyyteni salassa?',
        anounOrConfidential:
            'Sinulla on mahdollisuus tehdä ilmoitus joko nimettömänä tai luottamuksellisesti ilmoittajajärjestelmän kautta. Edellisessä tapauksessa henkilötietoja ei jaeta, kun taas jälkimmäisessä tapauksessa ilmiantaja jakaa henkilötietoja. Jos ilmoituksessasi jaetaan henkilötietoja, vain ne henkilöt, joilla on valtuudet tutkia tapausta, saavat ne käyttöönsä. Näin ollen toimitetun raportin luottamuksellisuutta kunnioitetaan joka tapauksessa.',
        willPersonsBeNotified:
            'Ilmoitetaanko asiasta kaikille henkilöille, joita raportti koskee?',
        concernedsNotified:
            'Asianomaiselle henkilölle tai asianomaisille henkilöille ilmoitetaan hänen osallisuudestaan raporttiin. Kussakin yksittäistapauksessa arvioidaan, milloin tämä ilmoitus voidaan antaa eteenpäin, jotta ilmoituksella ei ole seurauksia todisteiden keräämiselle.',
        noPersonalInfo:
            'Ilmoituksen tekijästä ei anneta mitään suoraa tai epäsuoraa tietoa asianomaiseen henkilöön nähden, vaikka olisitkin päättänyt tehdä ilmoituksen luottamuksellisesti ja näin ollen kertoa henkilöllisyytesi.',
        isSchemeSecured: 'Onko tämä ilmoittajajärjestelmä turvattu?',
        independencyGuarantee:
            'Ilmoituskanavaa ylläpitää Walor ApS, joka on riippumaton kolmas osapuoli, joka takaa järjestelmän turvallisuuden. Järjestelmä ei kirjaa IP-osoitteita eikä koneen tunnuksia ja salaa kaikki käsitellyt tiedot. Kaikkien asianosaisten osapuolten henkilötieto-oikeuksia kunnioitetaan. Ainoastaan vastuussa olevalla tapauskohtaisella työntekijällä on pääsy raporttiin.',
        whatIfImDissatisfied:
            'Mitä jos olen tyytymätön arviointiin tai raportin tulokseen?',
        informCompetentAuth:
            'On aina mahdollista ryhtyä lisätoimiin, jos katsot siihen olevan perusteita. Raportin kielteinen tulos ei missään tapauksessa estä sinua tekemästä niin. Kun olet tehnyt ilmoituksen ilmoittajajärjestelmän kautta, voit ilmoittaa tiedot toimivaltaisille viranomaisille. Tämän lisäksi ilmoituksia voidaan tarvittaessa tehdä myös Euroopan unionin toimielimille, elimille, laitoksille tai virastoille.',
    },
    whistleblowerPolicyPage: {
        whistleblowerPolicyHeading: 'Ilmoittajapolitiikka',
        backToHomescreen: 'Takaisin aloitusnäyttöön',
        whistleblowerPolicy: ':n ilmoittajapolitiikka',
        thePurposeOfThisWhistleblower:
            'Tämän ilmoittajapolitiikan tarkoituksena on selittää, miten',
        whistleblowerSchemeWorks:
            ' ilmoittajajärjestelmä toimii. Järjestelmän avulla voit tehdä ilmoituksia',
        aGoodUnderstanding:
            'Järjestelmän hyvä tuntemus voi siis estää sen, että mahdollisesti tärkeitä asioita jätetään ilmoittamatta.',
        introductionTo: 'Johdanto',
        whistleblowerScheme: ' ilmoittajajärjestelmä',
        isAResponsibleCompanyWith:
            'on vastuullinen yritys, jolla on avoin kulttuuri, jossa jokainen voi tuntea olonsa turvalliseksi ja ilmaista mielipiteensä, jos kokee väärinkäytöksiä tai mahdollisia lainrikkomuksia. Lähtökohtana on aina mahdollista ja erittäin kannatettavaa puhua näissä tilanteissa lähimmälle esimiehelle tai johdon (johtokunnan) jäsenelle, mutta on myös mahdollista käyttää ilmoittajakanavaa.',
        whistleblowerSchemeEnsuresProtection:
            ' ilmoittajajärjestelmä takaa suojan henkilöille, jotka ilmoittavat vakavista rikoksista ja asioista. Järjestelmää voidaan käyttää, jos koet, olet tietoinen tai sinulla on syytä epäillä, että tällaisia rikkomuksia on tapahtunut seuraavissa tapauksissa',
        bothInThePast:
            'sekä menneisyydessä, nykyisyydessä että lähitulevaisuudessa. Ilmoituksen voi tehdä joko nimettömänä tai luottamuksellisena.',
        whoCanUseThisWhistleblowingScheme:
            'Kuka voi käyttää tätä ilmoittajajärjestelmää?',
        whistleblowerSchemeCanBeUsedByAnyone:
            ' ilmoittajajärjestelmää voi käyttää kuka tahansa, jolla on tietoa seuraavista asioista.',
        whichTheyHaveAcquired:
            ' jotka hän on hankkinut työhönsä liittyvässä toiminnassaan ja',
        theCategoriesOfPeopleWho:
            'Henkilöryhmät, jotka voivat käyttää tätä ilmoittajajärjestelmää, ovat laajoja. Ilmoituksia voivat siis tehdä:',
        employees:
            'Työntekijät. Tämä koskee sekä koko- että osa-aikaisia työntekijöitä sekä työntekijöitä, jotka työskentelevät toistaiseksi voimassa olevalla tai määräaikaisella sopimuksella.',
        jobApplicants: 'Työnhakijat',
        paidOrUnpaidTrainees: 'Palkalliset tai palkattomat harjoittelijat',
        volunteers: 'Vapaaehtoiset',
        selfEmployedPersons: 'Itsenäiset ammatinharjoittajat',
        shareholders: 'Osakkeenomistajat',
        membersOfTheManagement:
            'Seuraavien yritysten johtokunnan, hallintoneuvoston tai muun hallintoelimen jäsenet',
        personsWorkingUnder:
            'Henkilöt, jotka työskentelevät seuraavien urakoitsijoiden, alihankkijoiden ja toimittajien valvonnassa ja johdolla',
        personsFallingUnderTheseCategories:
            'Näihin luokkiin kuuluvat henkilöt voivat käyttää ilmoittajajärjestelmää riippumatta siitä, ovatko he työsuhteessa seuraaviin tahoihin',
        hasCeased: 'on päättynyt, on vielä kesken tai ei ole vielä alkanut.',
        whatCanYouReport: 'Mitä voi ilmoittaa?',
        theWhistleblowerSchemeCanBeUsed:
            'Ilmoittajajärjestelmää voidaan käyttää kaikkiin tietoihin, jotka koskevat epäilyjä vakavista lainrikkomuksisya, yrityksistä salata tällaisia rikkomuksia tai muita vakavia asioita, joita on tapahtunut tai todennäköisesti tapahtuu seuraavissa maissa',
        alsoInTheCaseOfRasonable:
            'Tiedot voidaan ilmoittaa myös silloin, kun on perusteltua epäillä, että tällaisia rikkomuksia tai asioita on tapahtunut. Ilmoitukset on kuitenkin tehtävä vilpittömässä mielessä, eikä tätä ilmoitusjärjestelmää saa käyttää perusteettomien syytösten esittämiseen syyttömiä henkilöitä vastaan.',
        theViolationsWhich:
            'Rikkomukset, joista voidaan ilmoittaa ilmoittajajärjestelmän kautta, liittyvät EU-lainsäädännön erityisiin rikkomuksiin tai muihin vakaviin rikoksiin ja asioihin Tanskan lainsäädännön mukaan. Vähemmän vakavia asioita tai tavanomaisia työsuhdeasioita, kuten tyytymättömyyttä palkkaan tai vähäisiä kollegiaalisia ongelmia, ei ole tarkoitettu ilmoitettavaksi tämän ilmoittajajärjestelmän kautta.',
        activitesThatCanBeReported:
            'Järjestelmän kautta ilmoitettavat toimet liittyvät esimerkiksi korruptioon, ympäristönsuojeluun, kansanterveyteen, yksityisyyteen ja henkilötietoihin tai seksuaaliseen häirintään ja seksuaaliseen hyväksikäyttöön. Nämä ovat vain esimerkkejä. Myös muista vakavista asioista tai vakavista lainrikkomuksista voidaan ilmoittaa.',
        ifYouAreInDoubtAbout:
            'Jos olet epävarma siitä, onko havaintosi tai epäilysi vakavaa laatua ja pitäisikö siitä ilmoittaa vai ei, sinua kehotetaan tekemään niin. Kaikkiin tiedusteluihin vastataan ja ne käsitellään.',
        protectionOfWhistleblowers: 'Ilmoittajien suojelu',
        anyoneWhoReportsMatters:
            'Kuka tahansa, joka ilmoittaa edellä kuvatuista asioista vilpittömässä mielessä, on suojattu kaikenlaisilta kostotoimilta. Vastatoimet tarkoittavat suoria tai epäsuoria toimia tai laiminlyöntejä, jotka tapahtuvat työhön liittyvässä yhteydessä ilmoituksen seurauksena ja jotka aiheuttavat tai saattavat aiheuttaa perusteetonta haittaa ilmiantajalle. Tällaisia toimia voi olla monenlaisia, kuten esimerkiksi irtisanominen, työpaikan vaihtaminen, palkan alentaminen tai ilmoittajan maineen vahingoittaminen.',
        ifAWhistleblowerSuffers:
            'Jos ilmoittaja kärsii jonkinlaisista kostotoimista, joita seuraavat tahot harjoittavat',
        heOrSheWillBeCompensated:
            'hän saa tästä korvauksen. Irtisanomistapauksessa irtisanominen kumotaan ja työsuhde säilytetään tai luodaan uudelleen, jos ilmoittaja niin haluaa.',
        mayAlsoBeSubjectToOtherSanctions:
            'voidaan määrätä myös muita seuraamuksia.',
        howToReport: 'Miten raportoidaan?',
        reportsThatLieWithin:
            'Tämän politiikan soveltamisalaan kuuluvat ilmoitukset käsittelee yrityksen sovitun yhteyshenkilön valitsema henkilö tai henkilöt, jotka ovat:',
        onlyThesePersonsAreAuthorised:
            'Ainoastaan näillä henkilöillä on oikeus suorittaa tutkinta. Joissakin tapauksissa tutkinta voidaan kuitenkin suorittaa ulkopuolisten osapuolten, kuten asianajajien tai tilintarkastajien, avulla.',
        howAreTheReportsProcessed: 'Miten raportit käsitellään?',
        youWilRecieveAnAcknowledgement:
            'Saat seitsemän päivän kuluessa ilmoituksen raportin vastaanottamisesta. Tämän jälkeen raportti tarkastetaan alustavasti. Jos raportti osoittautuu huomattavan perusteettomaksi, se hylätään. Ilmoituksen tehneelle henkilölle ilmoitetaan tästä mahdollisimman pian. Jos alustavassa tutkimuksessa kuitenkin todetaan, että ilmoitusta ei voida pitää erityisen perusteettomana, sitä tutkitaan edelleen. Tapaus käsitellään sen jälkeen sisäisesti. Ilmoittaja saa palautetta mahdollisista päivityksistä tai tuloksista mahdollisimman pian. Sisäisellä tutkimuksella voi olla seurauksia asianosaiselle tai asianosaisille. Jos tapaus on erityisen vakava, se voidaan myös siirtää poliisille lisätutkimuksia varten. Kun kaikki tutkimukset on suoritettu ja mahdolliset seuraamukset määrätty, tapaus lopetetaan. Sen jälkeen se poistetaan järjestelmästä.',
        subsequentClarificationAndCollection:
            'Myöhemmät selvitykset ja lisäasiakirjojen kerääminen',
        whenYouCreateAReport:
            'Kun luot raportin, sinulla on mahdollisuus kirjautua järjestelmään ja nähdä, onko sosiaalityöntekijä esittänyt tapaukseen liittyviä lisäkysymyksiä tai pyytänyt lisäasiakirjoja. Mahdollinen myöhempi vuoropuhelu riippuu yksinomaan siitä, onko ilmiantaja halukas kirjautumaan järjestelmään ja vastaamaan tapauksen käsittelijän kysymyksiin.',
        confidentialityAndITSecurity: 'Luottamuksellisuus ja tietoturva',
        aReportCanBeMadeEither:
            'Ilmoituksen voi tehdä joko nimettömänä tai luottamuksellisena. Ensin mainittu tarkoittaa sitä, että henkilötietoja ei jaeta, mutta jälkimmäinen tarkoittaa sitä, että ilmiantaja jakaa henkilötietoja. Jos ilmoituksessasi jaetaan henkilötietoja, vain ne henkilöt, joilla on valtuudet tutkia tapausta, saavat ne käyttöönsä. Näin ollen toimitetun raportin luottamuksellisuutta kunnioitetaan joka tapauksessa.',
        theWhistleblowerReportingChannel:
            'Ilmoituskanavaa ylläpitää Walor ApS, joka on riippumaton kolmas osapuoli, joka takaa järjestelmän turvallisuuden. Järjestelmä ei kirjaa IP-osoitteita eikä koneen tunnuksia ja salaa kaikki käsitellyt tiedot. Ainoastaan asiasta vastaavalla työntekijällä on pääsy raporttiin.',
        noticeOfPersonConcerned: 'Ilmoitus asianomaiselle henkilölle',
        theRecipientOfTheReport:
            'Ilmoituksen vastaanottaja on velvollinen antamaan ilmoittajalle tietoja ilmoitetusta tapahtumasta. Kussakin yksittäistapauksessa arvioidaan, milloin ilmoitus voidaan antaa eteenpäin, jotta ilmoituksella ei ole seurauksia todisteiden keräämiselle.',
        noInformationEitherDirect:
            'Ilmoituksen tekijästä ei anneta mitään suoraa tai epäsuoraa tietoa asianomaiseen henkilöön nähden, vaikka ilmoittaja olisikin päättänyt tehdä ilmoituksen luottamuksellisesti.',
        questions: 'Kysymykset',
        allQuestions:
            'Kaikki ilmoittajajärjestelmää koskevat kysymykset voidaan osoittaa seuraavaan osoitteeseen',
    },
    newCaseFlow: {
        orisExtraText: 'BS ei sisällä potilaita koskevia henkilötietoja',
        headerInitiateReport: 'Raportin laatiminen',
        headerDescription: 'Kuvaus',
        headerChooseCaseworker: 'Valitse sosiaalityöntekijä',
        headerSummary: 'Yhteenveto',
        initiateReport: 'Aloittakaa raportti',
        howWouldYouLikeToAppear: 'Miten haluaisit näkyä raportissa?',
        or: 'tai',
        confidential: 'Luottamuksellinen',
        whatIstheDifferenceBetweenAnAnonymous:
            'Mitä eroa on nimettömällä ja luottamuksellisella ilmoituksella?',
        popUpWhatIstheDifferenceBetweenAnAnonymous:
            'Mitä eroa on nimettömällä ja luottamuksellisella ilmoituksella?',
        popUpAnonymous: 'Anonyymi',
        popUpAnonymousYouProvideYourEmployer:
            'Annat tietoja sekä mahdollisesta väärinkäytöksestä että havaitusta tapauksesta, tai onnettomuudesta, mutta sinun EI tarvitse antaa mitään henkilötietojasi.',
        popUpConfidential: 'Luottamuksellinen',
        popUpConfidentialYouProvideYourEmployer:
            'Annat tietoja sekä mahdollisesta väärinkäytöksestä että havaitusta tapauksesta, tai onnettomuudesta että joitakin tietoja itsestäsi.',
        confidentialInformation: 'Luottamukselliset tiedot',
        provideYourPersonalInformation:
            'Henkilökohtaisten tietojen antaminen raporttia varten',
        secretInformationNote:
            'Tietosi pidetään salassa, ja vain ilmiantajayksikkö voi tutustua niihin.',
        enterPhone: '12345678',
        describeTheEvents: 'Kuvaile tapahtumia',
        theMoreDetailedTheDescription:
            'Mitä yksityiskohtaisempi kuvaus on, sitä helpompi tapausta on tutkia.',
        allFieldsWith: 'Kaikki *-kentät ovat pakollisia',
        headline: 'Otsikko *',
        enterHeadline: 'Otsikko',
        description: 'Kuvaus',
        enterDescription: 'Kuvaus',
        recordAVoice: 'Äänitä ääniviesti',
        recordYourVoice: 'Äänitä ääniviesti',
        yourVoiceIsAutomatically:
            'Äänesi vääristyy automaattisesti anonymiteetin vuoksi.',
        listenToYourRecording: 'Kuuntele äänitteesi ennen lähettämistä',
        clickToStartRecording: 'Aloita tallennus napsauttamalla',
        voiceMessage: 'Ääniviesti',
        addRecording: 'Lisää tallennus',
        recordedVoiceMessage: 'Nauhoitettu ääniviesti',
        uploadFiles: 'Lataa tiedostoja',
        maximumFileSize: 'Tiedoston enimmäiskoko: 100 MB',
        addFiles: 'Lisää tiedostoja',
        youShouldBeAwareThatFilesCanContainMetadata:
            'Sinun on syytä olla tietoinen siitä, että tiedostot voivat sisältää metatietoja, jotka saattavat vaarantaa anonymiteettisi.',
        informationYouCould: 'Voisit sisällyttää seuraavat tiedot',
        whereAndWhen: 'Missä ja milloin tapahtuma järjestettiin?',
        whatIsYourKnowledge: 'Mitä tietoja teillä on tapauksesta?',
        whatDepartmentIsInvolved: 'Mikä osasto on mukana?',
        whatIsYourRelationship: 'Mikä on suhteesi organisaatioon?',
        reportSummary: 'Raportin tiivistelmä',
        summaryCaseWorker: 'Kuka saa raportin',
        summaryHowWouldYouLikeToAppear: 'Miten haluaisit näkyä raportissa?',
        summaryHeadline: 'Otsikko',
        summaryDescription: 'Kuvaus',
        summaryFiles: 'Tiedostot',
        expectToRecieveAnAnswer: 'Odota saavasi vastaus 7 päivän kuluessa',
        submitReport: 'Lähetä raportti',
        theReportHasBeenReceived: 'Kertomus on vastaanotettu',
        inOrderToAccessYourCaseAgain:
            'Jos haluat päästä tapaukseesi uudelleen käsiksi, sinun on tallennettava ja säilytettävä ilmoittajan koodi turvallisesti, sillä se on ainoa tapa päästä tapaukseesi uudelleen käsiksi.',
        whistleblowerCode: 'Whistleblower-koodi ',
        copyToClipboard: 'Kopioi koodi',
        stayUpdated: 'Pysy ajan tasalla',
        youHaveTheOpportunityToReceiveAMail:
            'Saat päivityksiä syöttämällä sähköpostiosoitteesi.',
        itIsImportantToMention: 'Sähköpostiasi ei jaeta.',
        theWhistleblowerCodeWillNot:
            'Whistleblower-koodia EI lähetetä postitse.',
        chooseContactFrame: {
            pickYourCaseworker: 'Valitse yhteyshenkilösi',
            pickExcludedCaseworker: 'Henkilöt, jotka saavat raportin',
            theContactWill: 'Valittu yhteyshenkilö saa raportin',
            theContactWillNot: 'Henkilöt, jotka saavat raportin',
            languages: 'Kielet',
            languagesNotGiven: 'Kieliä ei ole annettu',
            pickCaseworker: 'Valitse sosiaalityöntekijä',
        },
        popUpRememberYourWhistleblowerCode: 'Muista Whistleblower-koodisi',
        popUpItIsImportantThatYouSaveAndStoreTheWhistleblowerCode:
            'On tärkeää, että tallennat ja säilytät ilmiantajan koodin, jotta voit käyttää tapausta tulevaisuudessa.',
    },
    accessCaseFlow: {
        accessExistingReport: 'Pääsy olemassa olevaan raporttiin',
        inOrderToAccessYourCase:
            'Käytä saamasi koodia päästäksesi käsiksi olemassa olevaan ilmoitukseesi.',
        missingCode: 'Kadotitko koodisi?',
        createNewRaport: 'Luo uusi raportti.',
        enterYourWhistleblowerCode: 'Syötä whistleblower-koodisi',
        enterCode: 'Syötä koodi',
        logout: 'Kirjaudu ulos',
        info: 'Info',
        id: 'ID',
        appearance: 'Ulkonäkö',
        caseworkers: 'Asiakastyöntekijät',
        fromWhistleblower: 'Ilmiantajalta',
        timeline: 'Timeline',
        description: 'Kuvaus',
        translate: 'Käännä',
        translated: 'Käännetty',
        enterMessage: 'Kirjoita viesti',
        send: 'Lähetä',
        logoutOfReport: 'Kirjaudu ulos raportista',
        hiddenMsgOne: 'Tämä on päivämäärä, jolloin raportti luotiin.',
        hiddenMsgTwo:
            'Tämä on määräaika, johon mennessä sosiaalityöntekijän on annettava sinulle ensimmäinen vastaus.',
        hiddenMsgThree:
            'Tämä on määräaika, johon mennessä sosiaalityöntekijä antaa sinulle palautetta.',
        months: {
            january: 'Tammikuu',
            february: 'Helmikuu',
            march: 'Maaliskuu',
            april: 'Huhtikuu',
            may: 'Toukokuu',
            june: 'Kesäkuu',
            july: 'Heinäkuu',
            august: 'Elokuu',
            september: 'Syyskuu',
            october: 'Lokakuu',
            november: 'Marraskuu',
            december: 'Joulukuu',
        },
    },
    header: {
        dashboard: 'Kojelauta',
        cases: 'Tapaukset',
        clients: 'Asiakkaat',
        notifications: 'Ilmoitukset',
        readAll: 'Lue kaikki',
    },
    dashboardPartner: {
        headlineDashboard: 'Kojelauta',
        yourcases: 'Sinun tapauksesi',
        headline: 'Otsikko',
        notifications: 'Ilmoitukset',
        all: 'Kaikki',
        unread: 'Lukematon',
        noCases: 'Sinulla ei ole tapauksia',
        noNotifications: 'Sinulla ei ole ilmoituksia',
        yourStatistics: {
            yourStatistics: 'Tilastosi',
            open: 'Avaa',
            closed: 'Suljettu',
            forwarded: 'Edelleenlähetetty',
            totalCases: 'Tapaukset yhteensä',
            activeCases: 'Aktiiviset tapaukset',
            closedCases: 'Päättyneet tapaukset',
            forwardedCases: 'Välitetyt tapaukset',
        },
    },
    casesPartner: {
        headlineCases: 'Tapaukset',
        allCases: 'Kaikki tapaukset',
        headline: 'Otsikko',
        status: 'Tila',
        filter: 'Suodattimet',
        removeFilter: 'Poista suodattimet',
        myCases: 'Minun tapaukseni',
        actionRequired: 'Tarvittavat toimet',
        awaitingResponse: 'Odotetaan vastausta',
        seeClosedCases: 'Näytä päättyneet tapaukset',
        viewForwardedCases: 'Näytä välitetyt tapaukset',
        forwardedCases: 'Välitetyt tapaukset',
        searchCases: 'Etsi tapauksia',
        headlineClosedCases: 'Päättyneet tapaukset',
        closedCases: 'Päättyneet tapaukset',
    },
    caseworkerOpenReport: {
        transcription: 'Transkriptio',
        goBack: 'Palaa takaisin',
        reportBy: 'Raportin laatija',
        days: 'päivät',
        deadlineExceededTagOne: 'Määräaika ylitetty',
        deadlineExceededTagTwo: 'Lakisääteinen määräaika täytetty',
        hours: 'tunnilla',
        thisReportHasBeen: 'Tämän kertomuksen on lähettänyt',
        sevenDaysMessageOne:
            'Sinun on annettava kuittaus raportin vastaanottamisesta 7 päivän kuluessa sen vastaanottamisesta.',
        sevenDaysMessageTwo:
            'Lähetä viesti alla olevalle ilmoittajalle ja noudata lakia.',
        ninetyDaysMessageOne:
            'Ilmoittajan olisi saatava palaute mahdollisimman pian ja viimeistään kolmen kuukauden kuluessa ilmoituksen vastaanottamisesta.',
        ninetyDaysMessageTwo:
            'Palaute voi olla esimerkiksi sisäisen tutkinnan suunnitelma tai tulos.',
        exportCase: 'Vie',
        editInformation: 'Muokkaa tietoja',
        changeResponsibility: 'Vaihda vastuullinen',
        theNewResponsible: 'Uudelle vastuuhenkilölle ilmoitetaan sähköpostitse',
        thereAreNoOther: 'Muita sosiaalityöntekijöitä ei ole käytettävissä',
        forward: 'Lähetä eteenpäin',
        title: 'Otsikko',
        uncategorized: 'Luokittelematon ',
        categorizeCase: 'Luokittele tapaus',
        selectCategory: 'Valitse tämän tapauksen luokka',
        selectedCategory: 'Valittu luokka',
        close: 'Sulje',
        saveThis: 'Tallenna',
        frequentCategories: 'Usein esiintyvät luokat',
        allCategories: 'Kaikki luokat',
        ViewMoreCategories: 'Näytä lisää luetteloita',
        ViewLess: 'Näytä vähemmän',
        status: 'Tila',
        changeStatus: 'Tilan muuttaminen',
        youAreInTheProcess: 'Olet muuttamassa tilaa muotoon',
        awaitingResponse: 'Odotetaan vastausta',
        actionRequired: 'Tarvittavat toimet',
        confirm: 'Vahvista',
        caseDescription: 'Kuvaus',
        noFilesUploaded: 'Ei ladattuja tiedostoja',
        historyLog: 'Historia loki',
        preferredContact: 'Suositeltava yhteyshenkilö',
        noMessages: 'Ei viestejä',
        internalNotes: 'Sisäiset huomautukset',
        takeNotesHere: 'Lisää muistiinpanosi tähän, jotta et unohda sitä.',
        onlyTheCaseworkers: 'Tämä on vain sosiaalityöntekijöiden nähtävissä',
        enterNote: 'Kirjoita huomautus',
        saveNote: 'Tallenna',
        closeCase: 'Sulje tapaus',
        solved: 'Ratkaistu',
        denied: 'Hylätyt',
        forwarded: 'Edelleenlähetetty',
        addComment: 'Lisää kommentti',
        forwardedAt: 'Välitetty osoitteessa',
        severityScore: 'Vakavuuspisteet',
        comment: 'Miten',
        theCaseHasBeenForwardedTo: 'Tapaus on toimitettu seuraavalle taholle',
        theCaseHasBeenForwardedBy: 'Tapaus on toimittanut',
        caseClosed: 'Tapaus suljettu',
        toEnsureGDPR:
            'GDPR:n noudattamisen varmistamiseksi tämä tapaus poistetaan pysyvästi osoitteessa',
        theOptionToExportTheCase:
            'Tapauksen voi viedä PDF-muodossa tämän ajan kuluessa.',
        theDeletionOfTheCaseWillNot:
            'Tapauksen poistaminen ei vaikuta tilastojen yleiskatsaukseen.',
    },
    caseClosedViewPartner: {
        closeDate: 'Sulkeutumispäivä',
        comment: 'Kommentoi',
        readMore: 'Lue lisää',
        noComment: 'Ei kommenttia',
    },
    clientsPartner: {
        headlineClients: 'Asiakkaat',
        buttonAddNewClient: 'Lisää uusi asiakas',
        allClients: 'Kaikki asiakkaat',
        openCases: 'Avoimet tapaukset',
        closeCases: 'Päättyneet tapaukset',
        newCases: 'Uudet tapaukset',
    },
    singleClientViewPartner: {
        information: 'Tiedot',
        lineOfContact: 'Yhteyslinja',
        cases: 'Tapaukset',
        buttonSave: 'Tallenna',
        saveOrderOfContacts: 'Tallenna yhteystietojen järjestys',
        lineOfContactsHaveNot: 'Yhteyslinjaa ei ole luotu',
        deleteCaseworker: 'Poistetaan sosiaalityöntekijä',
        youAreAboutToDeleteTheCaseworker: 'Olet poistamassa asiakaspalvelijaa',
        theCaseworkerWillNotAbleToReceive:
            'Henkilökohtainen työnhakija ei voi enää vastaanottaa tulevia raportteja.',
        theCaseworkerCannotBeDeletedIf:
            'Asiakaspalvelijaa ei voi poistaa, jos hän liittyy avoinna olevaan tapaukseen.',
        thisActionCannotBeUndone: 'Tätä toimenpidettä ei voi peruuttaa',
        caseworkerHasCasesAndCannotBeDeleted:
            'Henkilöllä on yksi tai useampi aktiivinen tapaus, eikä häntä voida poistaa.',
        contactAdded: 'Uusi sosiaalityöntekijä on lisätty',
        contactUpdated: 'Päivitetty tapauskohtainen käsittelijä',
        inviteCaseworker: 'Kutsu sosiaalityöntekijä',
        allCases: 'Kaikki tapaukset',
        company: 'Yritys',
        headline: 'Otsikko',
        status: 'Tila',
        actionRequired: 'Tarvittavat toimenpiteet',
        awaitingResponse: 'Odotetaan vastausta',
        reOpenCase: 'Asian uudelleen avaaminen',
        youAreInTheProcessOfExporting: 'Olet viemässä tapausta',
        youAreInTheProcessOfReopening:
            'Olet ottamassa tapausta uudelleen käsiteltäväksi.',
        caseInfo: 'Tapauksen tiedot',
        arrivalDate: 'Saapumispäivä',
        closeDate: 'Sulkeutumispäivä',
        caseID: 'Tapauksen tunnus',
        theInternalInvestigationHasConcluded:
            'Sisäinen tutkinta on päättynyt, ja tapaus poistetaan seuraavassa osoitteessa',
        addLanguage: 'Lisää kieli',
        searchLanguage: 'Etsi kieli',
        languageNotFound: 'Kieltä ei löydy',
    },
    addNewClientPartner: {
        headlineAddNewClient: 'Lisää uusi asiakas',
        buttonCancel: 'Peruuta',
        searchForCompanyNameOrCompanyNumber:
            'Haku yrityksen nimellä tai yritystunnuksella',
        findCompany: 'Etsi yritys',
        enterInformationManually: 'Syötä tiedot manuaalisesti',
        thanksForYourSubscription: 'Olet lisännyt uuden asiakkaan',
        informationOnHowToGetStarted:
            'Tietoa siitä, miten asiakas pääsee alkuun',
        linkText:
            'Linkin kautta pääset digitaaliseen ilmoituskanavaan, jossa voit luoda ja lähettää ilmoituksia. Jaa tämä asiakkaan kanssa.',
        employeeLink: 'Työntekijän linkki',
        whistleblowerPolicyText:
            'Etsi asiakkaan ilmiantajakäytäntö alta ja pyydä asiakasta kirjautumaan foorumille lisäaineistoa varten.',
        whistleblowerPolicy: 'Ilmiantajapolitiikka',
        download: 'Lataa',
        sendLinkText: 'Lähetä tiedot asiakkaallesi',
        buttonSendEmail: 'Lähetä sähköpostia',
        popUpSendEmail: 'Lähetä sähköpostia',
        popUpText:
            'Olet lähettämässä Whistleblower-raportointikanavan linkkiä ja Whistleblower Policy -käytäntöä seuraavalle taholle.',
        popUpButtonSendInformation: 'Lähetä tietoja',
        popUpAreYouSureYouWantToCancelThisSession:
            'Oletko varma, että haluat peruuttaa tämän istunnon?',
        popUpYouWillLoseAllYourProgress: 'Menetät kaiken edistyksesi',
        popUpButtonNo: 'Ei',
        popUpButtonYes: 'Kyllä',
    },
    settingsPartner: {
        headlineSettings: 'Asetukset',
        myAccount: 'Tilini',
        automaticReply: 'Automaattinen vastaus',
        billing: 'Laskutus',
        users: 'Käyttäjät',
        logOut: 'Kirjaudu ulos',
        edit: 'Muokkaa',
        changePassword: 'Vaihda salasana',
        oldPassword: 'Vanha salasana',
        newPassword: 'Uusi salasana',
        passwordUpdate: 'Valmista!',
        passwordUpdadetSuccessfully: 'Salasana päivitetty onnistuneesti.',
        defaultLanguage: 'Oletuskieli',
        emailUpdated: 'Sähköposti päivitetty!',
        invoices: 'Laskut',
        verificationLinkSent:
            'Olemme lähettäneet vahvistuslinkin uuteen sähköpostiin. Sinut kirjaudutaan ulos ___',
        seconds: 'sekunnin päästä.',
        reactivateAutoReply: 'Automaattisen vastauksen aktivointi uudelleen',
        active: 'Aktiivinen',
        inactive: 'Inaktiivinen',
        autoReplyAlertMessage:
            'Automaattinen vastaus lähetetään 15 minuutin kuluttua ilmoituksen vastaanottamisesta.',
        added: 'Lisätty',
        openCases: 'Avoimet tapaukset',
        closedCases: 'Päättyneet tapaukset',
        buttonAddNewUser: 'Lisää uusi käyttäjä',
        popUpAddingANewUser: 'Uuden käyttäjän lisääminen',
        popUpAddPhoto: 'Lisää valokuva',
        popUpChangePhoto: 'Vaihda kuva',
        popUpText:
            'Käyttäjän on vahvistettava tilinsä aktivointisähköpostilla.',
        popUpButtonAddUser: 'Lisää käyttäjä',
        myAccountFrame: {
            somethingWentWrong: 'Jokin meni pieleen. Yritä uudelleen.',
            yourChangesHaveBeen: 'Muutoksesi on tallennettu onnistuneesti',
        },
        oldAddPaymentMethodFrame: {
            paymentMethods: 'Laskutus',
            addPaymentMethod: 'Lisää maksutapa',
            defaultPaymentMethod: 'Oletusmaksutapa',
            yourDefaultPaymentMethod:
                'Oletusmaksutapaa käytetään kaikkiin tuleviin maksuihin. Jos sinulla on erääntynyt tilausmaksu, yritämme veloittaa sen automaattisesti.',
            defaultPaymentMethodMissing: 'Oletusmaksu puuttuu',
            otherPaymentMethods: 'Muut maksutavat',
            otherPaymentMethodsYouHave:
                'Muut lisäämäsi maksutavat. Merkitse se, jota haluat käyttää oletuksena.',
            currentPlan: 'Nykyinen suunnitelma',
            belowYouCanSee: 'Tässä näet valitun tilaussuunnitelman.',
            cardNumber: 'Kortin numero',
            expireDate: 'Päättymispäivä',
            addPaymentMethodModal: {
                addPaymentMethod: 'Lisää maksutapa',
                cancel: 'Peruuta',
                card: 'Kortti',
                invoice: 'Lasku',
                errorLoadingStripe: 'Virhe, Stripen lataaminen',
                fullName: 'Koko nimi',
                email: 'Sähköposti',
                address: 'Osoite',
                city: 'Kaupunki',
                zipCode: 'Postinumero',
                bankInformation: 'Pankin tiedot',
                cardInformation: 'Kortin tiedot',
                loginMessage:
                    'Tämä saattaa kestää muutaman minuutin. Ole kärsivällinen.',
            },
        },
        addPaymentMethodFrame: {
            creditOrDebit: 'Luotto- tai pankkikortti',
            invoice: 'Lasku',
            addACard: 'Lisää kortti maksutapana',
            useAnInvoice: 'Käytä laskua maksutapana',
            active: 'Aktiivinen',
            freeTrial: 'Ilmainen kokeilu',
            canceled: 'Peruutettu',
            manageSubscription: 'Hallitse tilausta',
            addPaymentMethod: 'Lisää maksutapa',
            reactiveSubscription: 'Aktivoi tilaus uudelleen',
            cancelSubscription: 'Peruuta tilaus',
            youCanCancel:
                'Voit peruuttaa tilauksesi milloin tahansa. Sinulla on edelleen pääsy tilillesi, kunnes nykyinen laskutuskausi päättyy.',
            needHelp: 'Tarvitsen apua',
            areYouSure: 'Haluatko varmasti peruuttaa tilauksesi',
            pleaseLetUsKnow:
                'Kerro meille, jos sinulla on jokin erityinen huolenaihe, johon voimme puuttua ennen lähtöäsi.',
            ifYourCertain:
                'Jos olet varma peruutuksesta, olemme valmiina palaamaan, kun olet valmis palaamaan.',
            noGoBack: 'Ei, mene takaisin',
            itIsToo: 'Se on liian kallista',
            weDidNotUse: 'Emme käyttäneet alustaa',
            other: 'Muuta',
            whyAreYouCanceling: 'Miksi peruut tilauksesi',
            yourSubscriptionHasBeen: 'Tilauksesi on peruutettu',
            yourPlanIsActiveUntil: 'Suunnitelmasi on aktiivinen, kunnes',
            youWillContinue: 'Sinulla on edelleen pääsy tilillesi siihen asti.',
            editPaymentMethod: 'Muokkaa maksutapaa',
            currentPaymentMethods: 'Nykyiset maksutavat',
            chooseAnotherMethod: 'Valitse toinen menetelmä',
            paymentFailed: 'Maksu epäonnistui',
            yourPaymentHasFailed: 'Maksusi on epäonnistunut',
            theInvoiceHasNotBeenPayed: 'Laskua ei ole maksettu',
            doYouNeedHelp: 'Tarvitsetko apua',
            weWillGetInContact: 'Otamme sinuun yhteyttä 2 tunnin kuluessa.',
            talkToYouSoon: 'Jutellaan pian',
            getBack: 'Palaa takaisin.',
            submit: 'Lähetä',
            day: 'Päivä',
            days: 'Päivät',
            yourCardHasExpired: 'Korttisi on vanhentunut',
            pdf: 'PDF',
            whistleblowingSoftware: 'Whistleblowing-ohjelmisto',
            amount: 'Määrä',
            back: 'Takaisin',
            subscription: 'Tilaus',
            chooseThePlanThat:
                'Valitse järjestely, joka vastaa organisaatiosi kokonaishenkilöstömäärää.',
            employees: 'Työntekijät',
            year: 'vuosi',
            currentPlan: 'Nykyinen suunnitelma',
            newPlan: 'Uusi suunnitelma',
            choosePlan: 'Valitse suunnitelma',
        },
        invoicesFrame: {
            billingHistory: 'Laskutushistoria',
            invoice: 'lasku',
            amount: 'Määrä',
            plan: 'Suunnitelma',
            status: 'Tila',
            paid: 'Maksettu',
            waitingToPay: 'Odottaa maksua',
            download: 'Lataa',
        },
        userInformation: 'Käyttäjän tiedot',
    },
    clientView: {
        information: 'Tiedot',
        lineOfContact: 'Asiakastyöntekijät',
        customization: 'Mukauttaminen',
        whistleblowerPolicy: 'Ilmiantajapolitiikka',
        reportingChannel: 'Raportointikanava',
        copyLinkReportingChannel: 'Kopioi linkki raportointikanavaasi',
        downloadQRCodeImage: 'Lataa kuva täältä',
        downloadQRCode: 'Lataa raportointikanavan QR-koodi',
        copyLinkWhistleblowerPolicy:
            'Kopioi linkki ilmiantajia koskevaan toimintaohjeeseen.',
        reportingChannelLanguage: 'Raportointikanavan kieli:',
        reportingChannelLanguageError: 'Kanavan kielivirheestä ilmoittaminen:',
        qrCode: 'QR-koodi',
        trainingMaterial: 'Koulutusmateriaali',
        copyLinkToTrainingMaterial: 'Kopioi linkki koulutusmateriaaliin',
        contactPerson: 'Yhteyshenkilö',
        willBeDisplayedReportLink:
            'Seuraavat tiedot näytetään ilmiantajalle raportointilinkissä.',
        languages: 'Kielet',
        notes: 'Huomautukset',
        preview: 'Esikatselu',
        pickYourCaseworker: 'Valitse työntekijäsi',
        theContactWill: 'Valitut työntekijät saavat raportin',
        note: 'Huomautus',
        editProfilePicture: 'Muokkaa profiilikuvaa',
        addPhoto: 'Lisää valokuva',
        changePhoto: 'Vaihda kuva',
        addQuestion: 'Lisää kysymys',
        reportQuestions: 'Raportin kysymykset',
        addAQuestion: 'Lisää omat kysymyksesi',
        questionOnReportingChannel: 'Raportointikanavaasi koskevat kysymykset',
        dragToChangeOrderOfQuestions:
            'Vedä ja muuta kysymysten järjestystä tässä.',
        saveOderOfQuestions: 'Tallenna kysymysten järjestys',
        industrySuggestedQuestionTitle:
            'Seuraavassa on joitakin suositeltuja kysymyksiä, jotka liittyvät juuri sinun toimialaan.',
        suggestedQuestionText:
            'Nämä tarjoukset perustuvat toimialaanne. Käytä niitä, inspiroidu tai jätä ne pois.',
        addQuestionModal: {
            addingANewQuestion: 'Uuden kysymyksen lisääminen',
            newQuestion: 'Uusi kysymys',
            addQuestion: 'Lisää kysymys',
        },
    },

    verifyEmail: {
        verifyEmail: 'Vahvista sähköpostiosoite',
        emailVerified: 'Sähköposti vahvistettu',
        yourEmailHasBeen:
            'Sähköpostisi on vahvistettu, voit nyt mennä kirjautumissivulle ja kirjautua sisään sähköpostiosoitteellasi ja äskettäin luodulla salasanalla.',
        goToLogin: 'Siirry kirjautumiseen',
        verifyNewEmail: 'Varmistettu!',
        loginWithNewEmail: 'Voit nyt kirjautua sisään uudella sähköpostilla.',
    },
    nextButton: {
        nextStep: 'Seuraava',
    },
    finishButton: {
        finish: 'Valmistele',
    },
    closeButton: {
        close: 'Sulje',
    },
    dropdown: {
        countryNotFound: 'Maata ei löydy',
    },
    copiedText: {
        copied: 'Kopioitu',
    },
    you: {
        you: 'Sinä',
    },
    warningText: {
        warning: 'Varoitus',
    },
    deleteText: {
        delete: 'Poista',
    },
    inactiveModal: {
        inactivityWarning: 'Inaktiivisuusvaroitus',
        youWillBeLoggedOut: 'Sinut kirjaudutaan ulos',
        youHaveBeenInactive:
            'Et ole ollut aktiivinen 14 minuuttiin. Kun ajastin loppuu, sinut kirjaudutaan ulos.',
        stayLoggedIn: 'Pysy kirjautuneena sisään',
    },
    notifications: {
        newMessage: 'Uusi viesti',
        deadlineAlert: 'Määräaikahälytys',
        newReport: 'Uusi raportti',
        reportAssigned: 'Annettu raportti',
        reportUnassigned: 'Ilmoita, ettei sitä ole osoitettu',
    },
    historyLog: {
        forwarded: 'Edelleenlähetetty',
        exported: 'Viety',
        changeResponsible: 'Vaihda vastuullinen',
        caseViewed: 'Tapaus tarkasteltu',
        fileUploaded: 'Ladattu tiedosto',
        newMessage: 'Uusi viesti',
        statusUpdated: 'Tila päivitetty',
        caseDelegation: 'Asian siirtäminen',
        reportCreated: 'Raportti luotu',
        to: 'henkilölle',
        by: 'henkilöltä',
        from: 'osoitteesta',
        caseHasBeenForwarded: 'Tapaus on lähetetty eteenpäin',
        caseHasBeenExported: 'Tapaus on viety',
        caseHasBeenReassigned: 'Tapaus on siirretty',
        caseHasBeenViewed: 'Tapaus on katsottu',
        categoryIsChanged: 'Kategoria on vaihdettu',
        messageFrom: 'Viesti',
        caseAssigned: 'Määritetty tapaus',
        newCase: 'Uusi tapaus',
        caseClosed: 'Tapaus suljettiin',
        caseClosedBy: 'Tämän tapauksen on päättänyt',
        caseReopened: 'Tapaus otettiin uudelleen käsiteltäväksi',
        caseReopenedBy: 'Tapauksen on ottanut uudelleen käsiteltäväksi',
        internalNote: 'Sisäinen huomautus',
        internalNoteHasBeen: 'Sisäinen huomautus on kirjoittanut',
        caseworkerAdded: 'Tapauskohtainen työntekijä lisäsi',
        caseworker: 'Työntekijä',
        wasAddedBy: 'lisäsi',
        newFile: 'Uusi tiedosto',
        newFileWasAdded: 'Uuden tiedoston on lisännyt',
        whistleblower: 'ilmiantaja',
        automaticReplySent: 'Automaattinen vastaus lähetetty',
    },
    validation: {
        nameOfTheCityMustBeAtLeast:
            'Kaupungin nimen on oltava vähintään 1 merkki.',
        nameOfTheCityCannot:
            'Kaupungin nimi voi olla enintään 85 merkkiä pitkä.',
        companyIdentificationNumberMust:
            'Numeron on oltava täsmälleen 8 merkkiä pitkä',
        addressIsToShort: 'Osoite on liian lyhyt',
        addressIsToLong: 'Osoite on liian pitkä',
        organizationIsToShort: 'Organisaatio on liian lyhyt',
        organizationIsToLong: 'Organisaatio on liian pitkä',
        mustBeAValidEmail: 'Pitää olla voimassa oleva sähköpostiosoite',
        firstNameCannotBeShorter: 'Etunimi ei voi olla lyhyempi kuin 2 merkkiä',
        firstNameCannotBeLonger: 'Etunimi voi olla enintään 26 merkkiä pitkä',
        lastNameCannotBeShorter: 'Sukunimi ei voi olla lyhyempi kuin 2 merkkiä',
        lastNameCannotBeLonger: 'Sukunimi voi olla enintään 26 merkkiä pitkä',
        passwordMustBeAtLeast: 'Salasanan on oltava vähintään 8 merkkiä',
        yourPasswordMustContainerAtLeastOneUppercase:
            'Salasanan on sisällettävä vähintään yksi iso kirjain.',
        yourPasswordMustContainerAtLeastOneLowercase:
            'Salasanan on sisällettävä vähintään yksi pieni kirjain.',
        yourPasswordMustContainerAtLeastOneSymbol:
            'Salasanan on sisällettävä vähintään yksi kirjainsymboli',
        yourPasswordMustContainerAtLeastOneNumber:
            'Salasanassasi on oltava vähintään yksi numerokirjain',
        passwordsMustMatch: 'Salasanojen on vastattava',
        notAValidPhoneNumber: 'Virheellinen puhelinnumero',
        titleOfPositionMustBe: 'Tehtävän otsikon on oltava yli 2 merkkiä pitkä',
        titleOfPositionCannotBe:
            'Tehtävän nimi voi olla enintään 26 merkkiä pitkä',
        mustSetAPriority: 'Sinun on asetettava prioriteetti',
        notAValidZipCode: 'Virheellinen postinumero',
        isLongEnough: 'Vähintään 8 merkkiä pitkä',
        hasUpperCaseLetter: 'Vähintään 1 iso kirjain [A-Z]',
        hasLowerCaseLetter: 'Vähintään 1 pieni kirjain [a-z]',
        hasNumber: 'Vähintään 1 numero [0-9]',
        hasSpecialCharacter: 'Vähintään 1 erikoismerkki[!@#$%^&*()\\-__+.]',
        passwordsMatch: 'Molemmat salasanat täsmäävät',
    },
    forwardReportPage: {
        selectCatagory: 'Valitse luokka',
        uncategorized: 'Luokittelematon ',
        accusationAndEvidenceScore: 'Syytteen ja todisteiden pisteytys',
        accusationScore: 'Syytteen pisteet',
        evidenceScore: 'Todisteiden pistemäärä',
        severityScore: 'Vakavuuden kokonaispistemäärä',
        lineOfContact: 'Yhteyslinja',
        lineOfContactReminder:
            'Varmista, että henkilö ei ole missään tapauksessa sekaantunut tähän tapaukseen, -',
        comment: 'Miten',
        pleaseLeaveComment: 'Jätä kommentti tänne',
        forwardCase: 'Eteenpäin suuntautuva tapaus',
        popUpConfirmForwardPartOne: 'Oletko varma, että haluat lähettää',
        popUpConfirmForwardPartTwo: 'osoitteeseen',
        popUpNo: 'Ei',
        popUpYes: 'Kyllä, olen varma.',
        minimumAbbreviated: 'Min',
        maximumAbbreviated: 'Max',
        saveThis: 'Tallenna tämä',
    },
    trialWarning: {
        goToSettings: 'Siirry asetuksiin',
        yourTrialHasEnded: 'Kokeilusi on päättynyt.',
        yourFreeTrialEndedOn: 'Ilmainen kokeilujaksosi päättyi:',
        yourFreeTrialEndsOn: 'Kokeilu päättyy vuonna',
        day: 'päivä',
        days: 'päivät',
    },
    categories: {
        accounting_irregularities: 'Kirjanpidon sääntöjenvastaisuudet',
        bribery: 'Lahjonta',
        consumer_protection: 'Kuluttajansuoja',
        duty_of_confidentiality: 'Salassapitovelvollisuus',
        embezzlement: 'Kavallus',
        extortion: 'Kiristys',
        financial_services_products_and_markets:
            'Rahoituspalvelut, tuotteet ja markkinat',
        food_safety_animal_health_and_welfare:
            'Elintarvikkeiden turvallisuus, eläinten terveys ja hyvinvointi',
        forgery: 'Väärentäminen',
        fraudulence: 'Petos',
        money_laundering_and_terrorist_financing:
            'Rahanpesu ja terrorismin rahoitus',
        occupational_health_and_safety: 'Työterveys ja -turvallisuus',
        products_safety_and_compliance:
            'Tuotteiden turvallisuus ja vaatimustenmukaisuus',
        protection_of_environment: 'Ympäristönsuojelu',
        protection_of_privacy_and_personal_data_and_security_of_network_and_information_systems:
            'Yksityisyyden ja henkilötietojen suojaaminen sekä verkko- ja tietojärjestelmien turvallisuus.',
        public_health: 'Kansanterveys',
        public_procurement: 'Julkiset hankinnat',
        radiation_protection_and_nuclear_safety:
            'Säteilysuojelu ja ydinturvallisuus',
        serious_or_recurring_conflicts_at_the_workplace:
            'Vakavat tai toistuvat konfliktit työpaikalla',
        sexual_harassment_and_sexual_assault:
            'Seksuaalinen häirintä ja seksuaalinen väkivalta',
        the_financial_interests_of_the_european_union:
            'Euroopan unionin taloudelliset edut',
        the_internal_market_of_the_european_union_including_state_aid_and_corporate_taxation:
            'Euroopan unionin sisämarkkinat, mukaan lukien valtiontuki ja yritysverotus.',
        theft: 'Varkaus',
        conflict_of_interest: 'Eturistiriita',
        transport_safety: 'Liikenneturvallisuus',
        non_whistleblower_case: 'Muu kuin ilmiantotapaus',
    },
    integrationModal: {
        thisAppWouldLikeToAccess:
            'Tämä sovellus haluaisi päästä käsiksi Walor-tiliisi. Sinun on annettava tähän lupa.',
        thisAppRequires: 'Tämä sovellus vaatii luvan',
        whistleblowingChannel: 'ilmiantokanava',
        whistleblowingPolicy: 'ilmiantajapolitiikka',
        employeeGuides: 'työntekijöiden oppaat',
        companyName: 'yrityksen nimi',
        approve: 'Hyväksy',
    },
    dacarpoPolicy: {
        itIsImportant:
            'On tärkeää, että tallennat whistleblower-koodin raportista, jotta pääset tapaukseen käsiksi jälkikäteen.',
        allQuestions:
            'Kaikki ilmoittajajärjestelmää koskevat kysymykset voi osoittaa henkilöstöosastollemme.',
        youWillReceive:
            'Saat seitsemän päivän kuluessa ilmoituksen raportin vastaanottamisesta.',
        afterThis: 'Tämän jälkeen suoritetaan raportin alustava tarkastelu.',
        ifTheReport:
            'Jos raportti osoittautuu erityisen perusteettomaksi, se hylätään. Tapauksen ilmoittaneelle henkilölle ilmoitetaan tästä mahdollisimman pian.',
        howeverIfTheInitial:
            'Jos alustavassa tutkimuksessa kuitenkin todetaan, että ilmoitusta ei voida pitää erityisen perusteettomana, se tutkitaan tarkemmin.',
        theCaseWillThen:
            'Tapaus käsitellään sen jälkeen sisäisesti. Ilmoittaja saa palautetta mahdollisista päivityksistä tai tuloksista mahdollisimman pian.',
        thisInternalInvestigation:
            'Sisäisellä tutkinnalla voi olla seurauksia asianosaiselle tai asianosaisille henkilöille.',
        ifTheCaseIs:
            'Jos tapaus on erityisen vakava, se voidaan myös siirtää poliisille lisätutkimuksia varten.',
        afterConducting:
            'Kun kaikki tutkimukset on suoritettu ja mahdolliset seuraamukset määrätty, tapaus lopetetaan. Sen jälkeen se poistetaan järjestelmästä.',
    },
    inviteCaseworkerModal: {
        inviteNewCaseworker: 'Kutsu uusi sosiaalityöntekijä',
        sendInvitation: 'Lähetä kutsu',
    },
    caseworkerSetupAccountPage: {
        setupYourWalor: 'Määritä Walor-tilisi',
        youHaveBeenInvited: 'Sinut on kutsuttu liittymään',
        onWalorAs: 'Walorin osalta',
        caseworker: 'sosiaalityöntekijä',
        personalInformation: 'Henkilökohtaiset tiedot',
        additionalInformation: 'Lisätietoja',
        preview: 'Esikatselu',
        profilePicture: 'Profiilikuva',
        thisPictureWillAppear:
            'Tämä kuva tulee näkyviin, kun ilmoittaja valitsee asiakaspalvelijat.',
        addPicture: 'Lisää kuva',
        notes: 'Huomautukset',
        typeNoteHere: 'Kirjoita muistiinpano tähän..',
        languages: 'Kielet',
        whichDepartmentAreYou: 'Mihin osastoon kuulut',
        chooseOneOption: 'Valitse yksi vaihtoehto',
        humanResources: 'Henkilöstöhallinto',
        compliance: 'Vaatimustenmukaisuus',
        finance: 'Rahoitus',
        operations: 'Toiminta',
        marketing: 'Markkinointi',
        other: 'Muut',
        notesHover: 'Muistiinpanot näytetään ilmiantajalle.',
        languagesHover:
            'Ilmoita puhumasi kielet, jotta ilmoittaja voi valita parhaiten sopivan työntekijän.',
    },
    reusable: {
        voiceRecording: 'Äänitallennus',
        email: 'Sähköposti',
        personalInformation: 'Henkilökohtaiset tiedot',
        firstName: 'Etunimi',
        lastName: 'Sukunimi',
        cancel: 'Peruuta',
        address: 'Osoite',
        city: 'Kaupunki',
        zipCode: 'Postinumero',
        country: 'Maa',
        numberOfEmployees: 'Työntekijöiden lukumäärä',
        phone: 'Puhelinnumero',
        position: 'Asema',
        companyInformation: 'Yrityksen tiedot',
        companyNumber: 'Yritystunnus',
        companyName: 'Yrityksen nimi',
        workEmail: 'Työsähköposti',
        enterWorkEmail: 'Työsähköposti',
        password: 'Salasana',
        anonymous: 'Anonyymi',
        files: 'Tiedostot',
        date: 'Päivämäärä',
        messages: 'Viestit',
        organization: 'Organisaatio',
        new: 'Uusi',
        search: 'Etsi',
        category: 'Luokka',
        save: 'Tallenna',
        confirmPassword: 'Vahvista salasana',
    },
    customizeWhistleblowerPolicy: {
        whistleblowerPolicy: 'Ilmiantajapolitiikka',
        whistleBlowerPolicyLanguages: 'Ilmiantajapolitiikka Kielet',
        reviseTheDefault:
            'Tarkista oletuskäytäntöä ennen käännösten lisäämistä',
        addLanguage: 'Lisää kieli',
        selectALanguage: 'Valitse kieli',
        translations: 'Käännökset',
        default: 'Oletus',
        deleteLanguage: 'Poista kieli',
        youAreAboutToDelete:
            'Olet poistamassa ilmiantajakäytäntöä koskevaa kieliasua',
        noGoBack: 'Ei, mene takaisin',
        restoreDefaultPolicy: 'Palauta oletuskäytäntö',
        youAreAbout:
            'Olet aikeissa palauttaa oletusarvoisen ilmiantokäytännön!',
        anyModifications:
            'Englanninkieliseen käännökseen tehdyt muutokset kumotaan.',
        restoreToDefault: 'Palauta oletusasetukset',
        youHaveTheOption:
            'Sinulla on mahdollisuus tehdä korjauksia ilmoittajakäytäntöihisi täällä. Kun teet muutoksia oletuskäytäntöön, sinun on käännettävä käytäntö niille kielille, joita haluat tukea. Voit käyttää alla olevaa automaattista kääntäjätyökalua ja muokata tätä.',
        autoTranslate: 'Automaattinen kääntäminen',
        addMoreLanguages: 'Lisää kieliä',
    },
    clientsPage: {
        clients: 'Asiakas',
        allClients: 'Kaikki asiakkaat',
        addClient: 'Lisää asiakas',
    },
    statisticsPage: {
        statistics: 'Tilastot',
        overview: 'Yleiskatsaus',
        compareToLastPeriod: 'Vertailu edelliseen kauteen',
        lastYear: 'Viime vuonna',
        actionStats: 'Toimintatilastot',
        distributionOfCategories: 'Luokkien jakautuminen',
        casesByMonth: 'Tapaukset kuukausittain',
        months: {
            jan: 'Jan',
            feb: 'Feb',
            mar: 'Mar',
            apr: 'Apr',
            may: 'May',
            jun: 'Jun',
            jul: 'Jul',
            aug: 'Aug',
            sep: 'Sep',
            oct: 'Oct',
            nov: 'Nov',
            dec: 'Dec',
        },
    },
}

export default fi
