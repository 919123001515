import axios from 'axios'
import config from '../../config'
import { Dispatch } from 'redux'
import { IntegrationActionType } from '../action-types'
import {
    FlashMessage,
    IntegrationInterface,
} from '../reducers/integrationReducer'

const { apiUrl } = config

    export const getIntegrationByClientId =
    (clientId: string) => async (dispatch: Dispatch) => {
        const res = await axios.get(
            `${apiUrl}/v1/integrations/clients/${clientId}`
        )

        dispatch({
            type: IntegrationActionType.GET_INTEGRATION_DATA,
            payload: res.data.data as IntegrationInterface,
        })

        return true
    }

export const storeIntegrationData =
    (integrationPartnerUuid: string, clientId: string, clientSecret: string) =>
    async (
        dispatch: Dispatch
    ): Promise<number | { integration_id: string }> => {
        try {
            const res = await axios.post(
                `${apiUrl}/v1/integrations/${integrationPartnerUuid}/store-data`,
                {
                    hr_on_client_id: clientId,
                    hr_on_client_secret: clientSecret,
                }
            )

            if (res.status !== 200) {
                return res.status
            }

            return res.data.data
        } catch (err: any) {
            return err.response.status
        }
    }

export const approveIntegration =
    (integrationId: string) =>
    async (dispatch: Dispatch): Promise<boolean> => {
        try {
            const res = await axios.post(
                `${apiUrl}/v1/integrations/${integrationId}/approve`
            )

            if (res.status !== 204) {
                return false
            }

            dispatch({
                type: IntegrationActionType.APPROVE_INTEGRATION,
            })

            setIntegrationFlashMessage({
                type: 'success',
                message: 'Integration successful',
            })

            return true
        } catch (err: any) {
            return false
        }
    }

export const cancelIntegration =
    (integrationId: string) =>
    async (dispatch: Dispatch): Promise<boolean> => {
        const res = await axios.post(
            `${apiUrl}/v1/integrations/${integrationId}/cancel`
        )

        if (res.status !== 204) {
            return false
        }

        setIntegrationFlashMessage({
            type: 'error',
            message: 'Integration cancelled',
        })

        return true
    }

const setIntegrationFlashMessage = (message: FlashMessage) => ({
    type: IntegrationActionType.SET_FLASH_MESSAGE,
    payload: message,
})

// const clearIntegrationMessage = () => ({
//     type: HrOnIntegrationActionType.CLEAR_FLASH_MESSAGE,
// })
