import styled from 'styled-components'
import { FiArrowRight, FiPlay } from 'react-icons/fi'
import { useActions, useTranslation, useTypedSelector } from '../../../../hooks'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import ReportingChannelStepsList from './components/ReportingChannelStepsList'
import ReportingChannelCard from './components/ReportingChannelCard'
import { useEffect } from 'react'

interface NavigationLinkProps {
    active: boolean
}

const ReportingChanelContainer = styled.div`
    padding: 8rem 15rem;
    width: 100%;
    @media screen and (min-width: 1200px) and (max-width: 1600px) {
        padding-block-start: 3rem;
        padding-block-end: 0;
    }

    @media screen and (max-width: 600px) {
        padding-block-start: 4rem;
        padding-block-end: 0;
        padding-inline: 2rem;
    }
`
const ReportingChannelContent = styled.div`
    max-height: 70vh;
    display: flex;

    @media screen and (max-width: 1200px) {
        height: auto;
        flex-direction: column;
        gap: 2rem;
        max-height: 100%;
    }
`
const NavigationLinkContainer = styled.div`
    display: flex;
    align-items: center;
    max-height: 100vh;

    @media screen and (max-width: 600px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 0.5rem;
    }
`
const CompanyName = styled.h3<NavigationLinkProps>`
    font-size: 2.4rem;
    font-weight: ${({ active }) => (active ? 600 : 300)};
    color: ${({ theme }) => theme.secondaryDark};

    @media screen and (max-width: 600px) {
        font-size: 2rem;
    }
`
const ReportingChanelTitle = styled.h3<NavigationLinkProps>`
    font-size: 2.4rem;
    font-weight: ${({ active }) => (active ? 600 : 300)};
    color: ${({ theme }) => theme.secondaryDark};

    @media screen and (max-width: 600px) {
        font-size: 1.4rem;
    }
`
const NavigationLinkDivider = styled.div`
    height: 2.4rem;
    width: 0.2rem;
    margin: 0 2rem;
    background: ${({ theme }) => theme.primary};
    opacity: 0.2;

    @media screen and (max-width: 600px) {
        display: none;
    }
`
const Line = styled.div`
    height: 0.2rem;
    width: 100%;
    background: ${({ theme }) => theme.primary};
    opacity: 0.2;
    margin: 2rem 0;
`
const ReportAccessContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2.2rem;
    height: 70vh;

    @media screen and (max-width: 1200px) {
        height: auto;
    }
`

const InfoBoxContainer = styled.div`
    display: flex;
    width: 70%;
    flex-direction: column;
    border: 1px solid #e9e9e9;
    min-height: 70vh;

    @media (max-width: 1200px) {
        width: 100%;
        min-height: 60vh;
    }

    @media screen and (max-width: 600px) {
        padding-top: 1vw;
        border: none;
    }
`

const InfoBoxHeader = styled.div`
    width: 100%;
    background: ${({ theme }) => `${theme.blue}20`};
    border-radius: 0.5rem 0.5rem 0 0;
    display: flex;
    justify-content: space-between;
    padding-inline: 2rem;
    padding-block: 1rem;
    align-items: center;
    color: ${({ theme }) => theme.primary};
    height: 20%;

    @media screen and (max-width: 1000px) {
        height: auto;
        padding-block: 2rem;
    }

    @media screen and (max-width: 600px) {
        padding-block: 3rem;
        border-radius: 0.7rem 0.7rem 0 0;
    }
`
const InfoBoxFlex = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`

const InfoTitle = styled.h2`
    font-family: 'NeueMontreal-medium';
    font-style: normal;
    font-size: 1.8rem;
    line-height: 21px;
    color: #222330;

    @media screen and (max-width: 600px) {
        font-size: 1.6rem;
    }
`

const EmployeeGuideButton = styled.button`
    color: #222330;
    opacity: 0.5;
    padding: 0.5rem;
    cursor: pointer;
    font-size: 12px;
`

const PlayVideo = styled(Link)`
    height: 9rem;
    width: 9rem;
    background-color: #247dd0;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    line-height: 0;

    @media screen and (max-width: 1505px) {
        height: 8rem;
        width: 8rem;
    }

    @media screen and (max-width: 1400px) {
        height: 6rem;
        width: 6rem;
    }
`
const PlayVideoLogo = styled.div`
    padding-block-end: 1rem;
`
const PlayVideoText = styled.p`
    font-size: 1rem;
    font-family: 'NeueMontreal-medium';
`
const PlayVideoContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 3rem;
    color: white;

    @media screen and (max-width: 1505px) {
        padding-top: 2.4rem;
    }

    @media screen and (max-width: 1400px) {
        padding-top: 1.5rem;
    }
`
const CompanyWhistleblowerPolicy = styled.div`
    display: flex;
    align-items: center;
    padding-inline-start: 2rem;
    border-top: 1px solid #e9e9e9;
    width: 100%;
    font-family: 'NeueMontreal-medium';
    font-style: normal;
    font-size: 1.5rem;
    color: #222330;
    cursor: pointer;
    height: 5rem;

    @media screen and (max-width: 1000px) {
        height: auto;
        padding-block: 3rem;
    }
`

const ReportingChannelHomepage: React.FC = () => {
    const translation = useTranslation()
    const {
        companyName,
        reportingChannelLanguage,
        customWbPolicyName,
        organizationalCode,
    } = useTypedSelector((state) => state.whistleblower)
    const history = useHistory()
    const { changeLanguage } = useActions()

    useEffect(() => {
        if (reportingChannelLanguage) {
            changeLanguage(reportingChannelLanguage)
        }
        //eslint-disable-next-line
    }, [reportingChannelLanguage])

    return (
        <ReportingChanelContainer>
            <NavigationLinkContainer>
                <CompanyName active={true}>{companyName}</CompanyName>
                <NavigationLinkDivider />
                <ReportingChanelTitle active={false}>
                    {customWbPolicyName !== 'xxl'
                        ? translation.whistleblowerCreateCase
                              .whistleblowerScheme
                        : 'XXL Whistleblowing Channel'}
                </ReportingChanelTitle>
            </NavigationLinkContainer>
            <Line />
            <ReportingChannelContent>
                <ReportAccessContainer>
                    <ReportingChannelCard
                        onClick={() =>
                            history.push(
                                `/organization/${organizationalCode}/create-report`
                            )
                        }
                        title={translation.whistleblowerCreateCase.createReport}
                        tagline={
                            customWbPolicyName !== 'aalborg-teater'
                                ? translation.whistleblowerCreateCase
                                      .startReport
                                : translation.whistleblowerCreateCase
                                      .startReportConfidential
                        }
                    />
                    <ReportingChannelCard
                        onClick={() =>
                            history.push(
                                `/organization/${organizationalCode}/access-report`
                            )
                        }
                        title={
                            translation.whistleblowerCreateCase
                                .accessExistingReport
                        }
                        tagline={
                            translation.whistleblowerCreateCase
                                .accessExistingReport
                        }
                    />
                </ReportAccessContainer>
                <InfoBoxContainer>
                    <InfoBoxHeader>
                        <InfoBoxFlex>
                            <InfoTitle>
                                {
                                    translation.whistleblowerCreateCase
                                        .understandWhistleblowing
                                }
                            </InfoTitle>
                            <EmployeeGuideButton
                                onClick={() =>
                                    history.push(
                                        `/organization/${organizationalCode}/how-it-works`
                                    )
                                }
                            >
                                {translation.whistleblowerCreateCase.readMore}
                            </EmployeeGuideButton>
                        </InfoBoxFlex>
                        <PlayVideo
                            to={`/organization/${organizationalCode}/how-it-works`}
                        >
                            <PlayVideoContent>
                                <PlayVideoLogo>
                                    <FiPlay size={15} fill="white" />
                                </PlayVideoLogo>
                                <PlayVideoText>
                                    {
                                        translation.whistleblowerCreateCase
                                            .playvideo
                                    }
                                </PlayVideoText>
                            </PlayVideoContent>
                        </PlayVideo>
                    </InfoBoxHeader>
                    <ReportingChannelStepsList />
                    <CompanyWhistleblowerPolicy
                        onClick={() =>
                            history.push(
                                `/organization/${organizationalCode}/policy`
                            )
                        }
                    >
                        {customWbPolicyName !== 'xxl'
                            ? `${companyName} ${translation.whistleblowerCreateCase.companiesWhistleblowerPolicy}`
                            : 'XXL Whistleblowing Policy'}
                        <FiArrowRight color="black" size={12} />
                    </CompanyWhistleblowerPolicy>
                </InfoBoxContainer>
            </ReportingChannelContent>
        </ReportingChanelContainer>
    )
}
export default ReportingChannelHomepage
