import produce from 'immer'
import { IntegrationActions } from '../actions/integrationAction'
import { IntegrationActionType } from '../action-types'
import { Identity } from '../state-types/IdentityTypes'

export type IntegrationType = 'login' | 'register'

export interface FlashMessage {
    type: 'success' | 'error'
    message: string
}

export interface IntegrationInterface {
    temp?: {
        firstName?: string
        lastName?: string
        email?: string
        phone?: string
        companyName?: string
    }
    id: number
    name: null | string
    client_id: null | string
    integration_type: null | IntegrationType
    employee_id: null | string
    is_integration_confirmed: boolean
    uuid: null | string
    redirect_url: null | string
    partner: {
        identifier_name: string
    }
    organization: {
        data: {
            organizational_code: string
            name: string
            contact_lines: {
                data: Identity[]
            }
        }
    }
}

interface IntegrationState {
    activeIntegration: null | IntegrationInterface
    flashMessage: null | FlashMessage
}

const initialState: IntegrationState = {
    activeIntegration: null,
    flashMessage: null,
}

const reducer = produce(
    (state: IntegrationState = initialState, action: IntegrationActions) => {
        switch (action.type) {
            case IntegrationActionType.GET_INTEGRATION_DATA:
                state.activeIntegration = action.payload

                return state

            case IntegrationActionType.APPROVE_INTEGRATION:
                if (!state.activeIntegration) {
                    return state
                }

                state.activeIntegration.is_integration_confirmed = true

                return state

            case IntegrationActionType.SET_FLASH_MESSAGE:
                state.flashMessage = action.payload

                return state

            case IntegrationActionType.CLEAR_FLASH_MESSAGE:
                state.flashMessage = null

                return state

            default:
                return state
        }
    }
)

export default reducer
