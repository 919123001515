import styled from 'styled-components'
import { useTranslation, useTypedSelector } from '../../../hooks'
import { FiLink, FiFileText, FiBookOpen } from 'react-icons/fi'
import { BiBuildings } from 'react-icons/bi'
import { Modal, ModalContainer } from '../../styled/modals'
import { Card, CardBody, CardHeader } from '../../styled/cards'

const Container = styled.div`
    padding: 1.4rem;
    text-align: center;

    display: flex;
    flex-direction: column;
    align-items: center;
`

const InfoTextLight = styled.p`
    font-size: 1.4rem;
    color: ${({ theme }) => `${theme.primary}80`};
    margin-top: 1rem;
    margin-bottom: 2rem;
`

const Logo = styled.img`
    height: 3.5rem;
`

const InfoFlex = styled.div`
    display: flex;
    gap: 0.5rem;
    margin-bottom: 1rem;
`

const InfoText = styled.p`
    font-size: 1.4rem;
    font-weight: bold;
    color: ${({ theme }) => theme.primary};
`

const LinkIcon = styled(FiLink)`
    color: ${({ theme }) => theme.greyText};
    font-size: 1.4rem;
`

const FileIcon = styled(FiFileText)`
    color: ${({ theme }) => theme.greyText};
    font-size: 1.4rem;
`

const BookIcon = styled(FiBookOpen)`
    color: ${({ theme }) => theme.greyText};
    font-size: 1.4rem;
`

const BuildingIcon = styled(BiBuildings)`
    color: ${({ theme }) => theme.greyText};
    font-size: 1.4rem;
`

const ButtonContainer = styled.div`
    padding-top: 2.8rem;
    display: flex;
    gap: 1.4rem;
`

const Button = styled.div`
    padding: 0.4rem 1.4rem;
    border-radius: 2.4rem;
    font-size: 1.6rem;

    cursor: pointer;
`

const ApproveButton = styled(Button)`
    background-color: ${({ theme }) => theme.blue};
    color: ${({ theme }) => theme.white};
`

const CancelButton = styled(Button)`
    color: ${({ theme }) => theme.primary};
    border: 1px solid ${({ theme }) => theme.primary};
`

export interface IntegrationModalProps {
    approveCB?: () => void
    cancelCB?: () => void
}

const IntegrationModal: React.FC<IntegrationModalProps> = ({
    approveCB,
    cancelCB,
}) => {
    const { activeIntegration } = useTypedSelector((state) => state.integration)

    const translation = useTranslation()

    const handleApprove = async () => {
        if (!activeIntegration?.uuid) {
            return
        }

        if (approveCB) {
            approveCB()
        }
    }

    const handleCancel = async () => {}

    return (
        <>
            {' '}
            {activeIntegration && (
                <ModalContainer>
                    <Modal height="36rem" width="60rem">
                        <Card>
                            <CardHeader></CardHeader>
                            <CardBody>
                                <Container>
                                    <Logo
                                        src={`/img/integrations/logo/${activeIntegration.partner.identifier_name}.png`}
                                    />
                                    <InfoTextLight>
                                        {
                                            translation.integrationModal
                                                .thisAppWouldLikeToAccess
                                        }
                                    </InfoTextLight>
                                    <InfoFlex>
                                        <InfoText>
                                            {
                                                translation.integrationModal
                                                    .thisAppRequires
                                            }{' '}
                                            "
                                            {
                                                translation.integrationModal
                                                    .whistleblowingChannel
                                            }
                                            "
                                        </InfoText>
                                        <LinkIcon />
                                    </InfoFlex>
                                    <InfoFlex>
                                        <InfoText>
                                            {
                                                translation.integrationModal
                                                    .thisAppRequires
                                            }{' '}
                                            "
                                            {
                                                translation.integrationModal
                                                    .whistleblowingPolicy
                                            }
                                            "
                                        </InfoText>
                                        <FileIcon />
                                    </InfoFlex>
                                    <InfoFlex>
                                        <InfoText>
                                            {
                                                translation.integrationModal
                                                    .thisAppRequires
                                            }{' '}
                                            "
                                            {
                                                translation.integrationModal
                                                    .employeeGuides
                                            }
                                            "
                                        </InfoText>
                                        <BookIcon />
                                    </InfoFlex>
                                    <InfoFlex>
                                        <InfoText>
                                            {
                                                translation.integrationModal
                                                    .thisAppRequires
                                            }{' '}
                                            "
                                            {
                                                translation.integrationModal
                                                    .companyName
                                            }
                                            "
                                        </InfoText>
                                        <BuildingIcon />
                                    </InfoFlex>
                                    <ButtonContainer>
                                        <CancelButton onClick={handleCancel}>
                                            {translation.reusable.cancel}
                                        </CancelButton>
                                        <ApproveButton onClick={handleApprove}>
                                            {
                                                translation.integrationModal
                                                    .approve
                                            }
                                        </ApproveButton>
                                    </ButtonContainer>
                                </Container>
                            </CardBody>
                        </Card>
                    </Modal>
                </ModalContainer>
            )}
        </>
    )
}

export default IntegrationModal
