import { useHistory } from 'react-router'
import { useTypedSelector } from '../../../../../hooks'
import NavigationArrowLink from '../../../../reusable/buttons/NavigationArrowLink'
import {
    Container,
    BackToHomeScreen,
    PolicyContainer,
    Heading,
    Paragraph,
    NavigationLink,
    NavigationLinkContainer,
    NavigationLinkDivider,
    Li,
} from '../styled'
import styled from 'styled-components'

const Bold = styled.span`
    font-weight: bold;
`

const PsAuctionPolicy = () => {
    const history = useHistory()

    const { lineOfContacts } = useTypedSelector((state) => state.lineOfContact)

    return (
        <>
            {' '}
            <BackToHomeScreen>
                <NavigationArrowLink
                    onClickCallback={() => {
                        history.push('/')
                    }}
                    linkText={'Back to Home Screen'}
                />
            </BackToHomeScreen>
            <Container>
                <NavigationLinkContainer>
                    <NavigationLink active={true}>
                        Visselblåsarpolicy
                    </NavigationLink>
                    <NavigationLinkDivider />
                    <NavigationLink active={false}>
                        PS Auction AB
                    </NavigationLink>
                </NavigationLinkContainer>
                <PolicyContainer>
                    <Heading>1. Syfte</Heading>
                    <Paragraph>
                        Syftet med denna visselblåsarpolicy är att förklara hur
                        PS Auction AB:s visselblåsarsystem fungerar. Systemet
                        ger en möjlighet att rapportera misstänkta
                        oegentligheter eller lagbrott inom företaget. En god
                        förståelse för systemet kan förhindra att viktiga
                        ärenden inte rapporteras
                    </Paragraph>
                    <Heading>2. Visselblåsarsystemet</Heading>
                    <Paragraph>
                        PS Auction AB är ett ansvarsfullt företag med en öppen
                        kultur där alla ska känna sig trygga att rapportera
                        eventuella oegentligheter. Som förstahandsåtgärd
                        uppmuntras dialog med närmaste chef eller ledning.
                        Alternativt kan visselblåsarsystemet användas för att
                        lämna en anonym eller konfidentiell rapport.
                    </Paragraph>
                    <Paragraph>
                        Visselblåsarsystemet skyddar personer som anmäler
                        allvarliga missförhållanden och brott, oavsett om dessa
                        skett i det förflutna, nutid eller förväntas ske i
                        framtiden.
                    </Paragraph>
                    <Heading>3. Vem kan rapportera?</Heading>
                    <Paragraph>
                        Visselblåsarsystemet kan användas av personer som har
                        fått information inom ramen för sin arbetsrelaterade
                        verksamhet hos PS Auction AB. Följande grupper omfattas:
                    </Paragraph>
                    <Paragraph>
                        <ul>
                            <Li>Anställda (fast, visstid, heltid, deltid)</Li>
                            <Li>Arbetssökande</Li>
                            <Li>Praktikanter (betalda och obetalda)</Li>
                            <Li>Volontärer</Li>
                            <Li>Egenföretagare</Li>
                            <Li>Aktieägare</Li>
                            <Li>
                                Styrelseledamöter och personer i andra styrande
                                organ
                            </Li>
                            <Li>
                                Leverantörer, entreprenörer och
                                underentreprenörer
                            </Li>
                            <Li>Kunder</Li>
                        </ul>
                    </Paragraph>

                    <Paragraph>
                        Personer inom dessa kategorier kan använda systemet
                        oavsett om deras anställningsförhållande har upphört,
                        pågår eller inte har påbörjats.
                    </Paragraph>
                    <Heading>4. Vad kan rapporteras?</Heading>
                    <Paragraph>
                        Visselblåsarsystemet är avsett för att rapportera
                        allvarliga överträdelser av lagar och regler, försök att
                        dölja sådana överträdelser eller andra allvarliga
                        missförhållanden inom PS Auction AB. Exempel på ämnen
                        som kan rapporteras inkluderar:
                    </Paragraph>
                    <Paragraph>
                        <ul>
                            <Li>Korruption och mutor</Li>
                            <Li>Miljöbrott</Li>
                            <Li>Folkhälsa och säkerhet</Li>
                            <Li>Kränkningar av personuppgifter</Li>
                            <Li>Sexuella trakasserier och övergrepp</Li>
                        </ul>
                    </Paragraph>
                    <Paragraph>
                        Mindre allvarliga arbetsrelaterade ärenden, exempelvis
                        missnöje med lön eller interna konflikter, hänvisas till
                        andra interna rutiner.
                    </Paragraph>
                    <Paragraph>
                        Osäker på om en rapport bör skickas? Om du misstänker en
                        allvarlig överträdelse, uppmuntras du att rapportera.
                        Alla inkomna ärenden behandlas konfidentiellt.
                    </Paragraph>
                    <Heading>5. Skydd för visselblåsare</Heading>
                    <Paragraph>
                        Den som i god tro rapporterar allvarliga
                        missförhållanden skyddas från repressalier. Repressalier
                        kan inkludera, men är inte begränsade till:
                    </Paragraph>
                    <Paragraph>
                        <ul>
                            <Li>Uppsägning</Li>
                            <Li>Lönesänkning</Li>
                            <Li>Omplacering</Li>
                            <Li>Ryktesskada</Li>
                        </ul>
                    </Paragraph>
                    <Paragraph>
                        Vid repressalier har visselblåsaren rätt till
                        ersättning. Vid felaktig uppsägning kan anställningen
                        ogiltigförklaras och återupprättas.
                    </Paragraph>
                    <Heading>Hur rapporterar man?</Heading>
                    <Paragraph>
                        Rapporter behandlas av utvalda kontaktpersoner:
                    </Paragraph>
                    <Paragraph>
                        <ul>
                            {lineOfContacts.map((contact) => (
                                <Li>
                                    <Bold>
                                        {contact.first_name} {contact.last_name}
                                    </Bold>{' '}
                                    {'-'} {contact.contact_information.position}
                                </Li>
                            ))}
                        </ul>
                    </Paragraph>
                    <Paragraph>
                        Dessa personer har exklusiv behörighet att hantera
                        rapporter och kan i vissa fall involvera externa
                        experter, exempelvis advokater eller revisorer.
                    </Paragraph>
                    <Heading>7. Hantering av rapporter</Heading>
                    <Paragraph>
                        <ul>
                            <Li>
                                Inom <Bold>sju dagar</Bold> bekräftas
                                mottagandet av rapporten.
                            </Li>
                            <Li>
                                En första granskning avgör om rapporten är
                                välgrundad.
                            </Li>
                            <Li>
                                Om rapporten är grundlös avvisas den och
                                informanten underrättas.
                            </Li>
                            <Li>
                                Om rapporten bedöms relevant genomförs en
                                utredning.
                            </Li>
                            <Li>
                                Om ärendet är av allvarlig karaktär kan det
                                överlämnas till polis eller andra myndigheter.
                            </Li>
                            <Li>När utredningen avslutas raderas all data.</Li>
                        </ul>
                    </Paragraph>
                    <Heading> Efterföljande dialog och dokumentation</Heading>
                    <Paragraph>
                        Den som rapporterar kan få ytterligare frågor eller
                        begäran om dokumentation via systemet. Det är upp till
                        visselblåsaren att logga in och besvara frågorna
                    </Paragraph>
                    <Heading>9. Konfidentialitet och IT-säkerhet</Heading>
                    <Paragraph>
                        Rapporter kan lämnas <Bold>anonymt</Bold> eller
                        <Bold>konfidentiellt.</Bold> Vid konfidentiell
                        rapportering delas personlig information, men endast
                        behöriga handläggare har tillgång till den.
                    </Paragraph>
                    <Paragraph>
                        PS Auction AB:s visselblåsarsystem hanteras av{' '}
                        <Bold>Walor ApS</Bold>, en oberoende tredjepart som
                        garanterar systemets säkerhet genom kryptering och
                        anonymitetsskydd.
                    </Paragraph>
                    <Heading>10. Information till berörda personer</Heading>
                    <Paragraph>
                        Personer som rapporteras får information om anmälan, men
                        anmälarens identitet avslöjas aldrig. Bedömningen av när
                        en anmälan kommuniceras till den berörda personen sker
                        utifrån förutsättningar för att säkra bevisinsamlingen.
                    </Paragraph>
                    <Heading>11. Frågor</Heading>
                    <Paragraph>
                        För frågor om visselblåsarsystemet, kontakta PS Auction
                        AB:s HR-avdelning.
                    </Paragraph>
                </PolicyContainer>
            </Container>
        </>
    )
}

export default PsAuctionPolicy
