import styled from 'styled-components'
import { useTranslation, useTypedSelector } from '../../../../../hooks'

const ReportingChannelStepsContainer = styled.div`
    padding: 2rem;
    font-size: 1.6rem;
    max-width: 100%;
    display: inline-block;
    overflow-y: scroll;
    flex: 1;

    @media (max-width: 1000px) {
        width: 100%;
    }

    @media screen and (max-width: 600px) {
        margin-block-end: 0;
    }
`
const Paragraph = styled.p`
    margin-bottom: 2rem;
    font-size: 1.6rem;
    color: ${({ theme }) => theme.primary};
`

const ReportingChannelStepsContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1.6vh;
    margin-bottom: 1rem;

    @media screen and (min-width: 1200px) and (max-width: 1600px) {
        margin-top: 1.6rem;
    }

    @media screen and (max-width: 600px) {
        margin-block-start: 2rem;
    }
`

const ReportingChannelStepsTiTleAndNumber = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`

const ReportingChannelNumberBackground = styled.div`
    height: 2rem;
    width: 2rem;
    background-color: black;
    border-radius: 50%;
    position: relative;
    line-height: 0;

    @media screen and (max-width: 600px) {
        margin-block-end: 1rem;
    }
`
const ReportingChannelStepsNumber = styled.p`
    font-size: 1rem;
    margin-right: 1rem;
    color: white;
    position: absolute;
    top: 50%;
    width: 100%;
    text-align: center;
    font-family: 'NeueMontreal-medium';
`
const ReportingChanelStepsMainTitle = styled.h3`
    margin-block-start: 0;
`

const ReportingChannelStepsTitle = styled.h3`
    @media screen and (max-width: 600px) {
        display: none;
    }
`

const ReportingChannelStepsText = styled.p`
    font-size: 1.4rem;
    margin-right: 1rem;
    width: 100%;
    max-height: 8rem;
    overflow-y: auto;

    @media screen and (min-width: 1200px) and (max-width: 1600px) {
        font-size: 1.2rem;
    }

    @media screen and (max-width: 600px) {
        font-size: 1.5rem;
    }
`

const ReportingChannelStepsList: React.FC = () => {
    const translation = useTranslation()

    const { customWbPolicyName } = useTypedSelector(
        (state) => state.whistleblower
    )

    const reportingChannelList: {
        number: number
        title: string
        description: string
    }[] = [
        {
            number: 1,
            title: translation.whistleblowerCreateCase.createAReport,
            description:
                customWbPolicyName === 'oris'
                    ? translation.whistleblowerCreateCase.createAReportOris
                    : translation.whistleblowerCreateCase.createAReportSimply,
        },

        {
            number: 2,
            title: translation.whistleblowerCreateCase.investigatingthereport,
            description:
                translation.whistleblowerCreateCase.caseworkerinthecompany,
        },

        {
            number: 3,
            title: translation.whistleblowerCreateCase
                .answeradditionalquestions,
            description:
                translation.whistleblowerCreateCase.additionalQuestions,
        },
    ]

    return (
        <>
            {customWbPolicyName !== 'ioi' ? (
                <ReportingChannelStepsContainer>
                    <ReportingChanelStepsMainTitle>
                        {translation.whistleblowerCreateCase.howThisWorks}
                    </ReportingChanelStepsMainTitle>
                    {reportingChannelList.map((listItem) => {
                        return (
                            <>
                                <ReportingChannelStepsContent>
                                    <ReportingChannelStepsTiTleAndNumber>
                                        <ReportingChannelNumberBackground>
                                            <ReportingChannelStepsNumber>
                                                {listItem.number}
                                            </ReportingChannelStepsNumber>
                                        </ReportingChannelNumberBackground>
                                        <ReportingChannelStepsTitle>
                                            {listItem.title}
                                        </ReportingChannelStepsTitle>
                                    </ReportingChannelStepsTiTleAndNumber>
                                    <ReportingChannelStepsText>
                                        {listItem.description}
                                    </ReportingChannelStepsText>
                                </ReportingChannelStepsContent>
                            </>
                        )
                    })}
                </ReportingChannelStepsContainer>
            ) : (
                <ReportingChannelStepsContainer>
                    <ReportingChanelStepsMainTitle
                        style={{ marginBottom: '4rem' }}
                    >
                        How to use the IO Interactive Whistleblower Reporting
                        Channel.
                    </ReportingChanelStepsMainTitle>
                    <Paragraph>
                        As an employee at IO Interactive you can safely and
                        anonymously utilize this whistleblower channel to report
                        any knowledge or reasonable doubt of any wrongdoings in
                        our organisation where extra protection of the person
                        who reports and shares knowledge is needed. This is a
                        supplement to our internal tool Peakon for situations
                        where cases are more severe and need special attention.
                    </Paragraph>
                    <Paragraph>
                        The purpose of this channel is to enable you all to shed
                        light on potential wrongdoings that we would not have
                        been aware of otherwise.
                    </Paragraph>
                    <Paragraph>
                        All reports are treated confidentially and safely, and
                        you can choose if you want to be anonymous or
                        confidential as well as which person you would like to
                        handle your report. All reports are handled within the
                        timeline and guidelines set out in the Directive (EU)
                        2019/1937.
                    </Paragraph>
                </ReportingChannelStepsContainer>
            )}
        </>
    )
}

export default ReportingChannelStepsList
