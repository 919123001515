export enum ThemeActionType {
    SWITCH_THEME = 'switch_theme',
}

export enum TranslationActionType {
    SWITCH_LANGUAGE = 'switch_language',
}

export enum OnboardingActionType {}

export enum AuthActionType {
    REGISTER_USER = 'register_user',
    SELF_REGISTER_ORGANIZATION = 'self_register_organization',
    ONBOARDING_CREATE_LINE_OF_CONTACT = 'onboarding_create_line_of_contact',
    ONBOARDING_GENERATE_WHISTLEBLOWER_POLICY = 'onboarding_generate_whistleblower_policy',
    ORGANIZATION_FINISH_ONBOARDING = 'organization_finish_onboarding',
    REGISTER_ORGANIZATION = 'register_organization',
    VERIFY_LOGIN_CREDENTIALS = 'verify_login_credentials',
    SEND_2FA_TOKEN = 'send_2fa_token',
    VERIFY_2FA_TOKEN = 'verify_2fa_token',
    LOGIN = 'login',
    SET_LOADING = 'set_loading',
    SET_ERROR = 'set_error',
    SET_VALIDATION_ERRORS = 'set_validation_errors',
    CLEAR_VALIDATION_ERRORS = 'clear_validation_errors',
    VERIFY_COMPANY_INFORMATION = 'verify_company_information',
    VERIFY_EMAIL = 'verify_email',
    SEND_CLIENT_INFORMATION_EMAIL = 'send_client_information_email',
    SET_DYNAMIC_LOGIN_ERROR = 'set_dynamic_login_error',
    LOGIN_WITH_MAGIC_LOGIN_TOKEN = 'login_with_magic_login_token',
}

export enum IdentityActionType {
    GET_IDENTITY = 'get_identity',
    GET_IDENTITY_THROUGH_INTEGRATION = 'get_identity_through_integration',
    UPDATE_IDENTITY = 'update_identity',
    UPDATE_IDENTITY_ERROR = 'update_identity_error',
    CLEAR_UPDATE_IDENTITY_ERROR = 'clear_update_identity_error',
    IDENTITY_LOADING = 'identity_loading',
    UPDATE_PASSWORD = 'update_password',
    UPDATE_PASSWORD_SUCCESS = 'update_password_success',
    UPDATE_PASSWORD_ERROR = 'update_password_error',
    CLEAR_UPDATE_PASSWORD_ERROR = 'clear_update_password_error',
    SET_VERIFY_EMAIL_ERROR = 'set_verify_email_error',
    SET_VERIFY_EMAIL_SUCCESS = 'set_verify_email_success',
    CLEAR_IDENTITY_FLASH_MESSAGE = 'clear_identity_flash_message',
    UPDATE_SELECTED_LANGUAGE = 'update_selected_language',
    DELETE_PROFILE_IMAGE = 'delete_profile_image',
}

export enum ModalActionType {
    OPEN_MODAL = 'open_modal',
    CLOSE_MODAL = 'close_modal',
}

export enum WhistleblowerActionType {
    WHISTLEBLOWER_LOGIN = 'whistleblower_login',
    WHISTLEBLOWER_LOGOUT = 'whistleblower_logout',
    SET_WHISTLEBLOWER_LOADING = 'set_whistleblower_loading',
    SET_WHISTLEBLOWER_ERROR = 'set_whistleblower_error',
    RESET_WHISTLEBLOWER_ERROR_MESSAGE_STATE = 'reset_whistleblower_error_message_state',
    CLEAR_WHISTLEBLOWER_ERROR = 'clear_whistleblower_error',
    CREATE_REPORT = 'create_report',
    GET_REPORT = 'get_report',
    GET_MESSAGES = 'get_messages',
    ADD_MESSAGE = 'add_message',
    LEAVE_REPORT = 'leave_report',
    SHOW_LATEST_MESSAGE = 'show_latest_message',
    WHISTLEBLOWER_SHOW_LATEST_FILES = 'whistleblower_show_latest_files',
    ADD_REPORTER_EMAIL = 'add_reporter_email',
    SHOW_REPORT_ERRORS = 'add_report_errors',
    REFRESH_CASE_AFTER_FORWARD = 'refresh_cash_after_forward',
    CHANGE_REPORTING_CHANNEL_LANGUAGE = 'change_reporting_channel_language',
}

export enum CaseworkerActionType {
    SET_CASEWORKER_LOADING = 'set_caseworker_loading',
    SET_CASEWORKER_ERROR = 'set_caseworker_login_error',
    GET_ORGANIZATIONS = 'get_organizations',
    GET_OWN_REPORTS = 'get_own_reports',
    GET_ALL_REPORTS = 'get_all_reports',
    GET_CLOSED_REPORTS = 'get_closed_reports',
    GET_CLIENT_INFORMATION = 'get_client_information',
    GET_SELF = 'get_self',
    GET_SELF_ORGANIZATION = 'get_self_organization',
    ADD_CASEWORKER = 'add_caseworker',
    GET_ALL_CASEWORKERS = 'get_all_caseworkers',
    SET_FETCH_CASEWORKERS_LOADING = 'set_fetch_caseworkers_loading',
    GET_SINGLE_CASEWORKER = 'get_single_caseworker',
    SEARCH_ALL_CASES = 'search_all_cases',
    ADD_STATUS_FILTER = 'add_status_filter',
    ADD_CASE_TYPE_FILTER = 'add_case_type_filter',
    FILTER_ALL_CASES = 'filter_all_cases',
    REMOVE_ALL_FILTERS = 'remove_all_filters',
    SEARCH_CLOSED_REPORTS = 'search_closed_reports',
    SHOW_CREATE_CASEWORKER_VALIDATION_ERRORS = 'show_create_caseworker_validation_errors',
    ADD_CASEWORKER_VALIDATION = 'add_caseworker_validation',
    CLEAR_CASEWORKER_VALIDATION_ERRORS = 'clear_caseworker_validation_errors',
}

export enum ContactUserActionType {
    CONTACT_GET_SELF_REPORTS = 'contact_get_self_reports',
    CONTACT_USER_LOADING = 'contact_user_loading',
}

export enum ReportHistoryLogActionType {
    GET_HISTORY_LOG = 'get_history_log',
}

export enum ReportInternalNoteActionType {
    GET_INTERNAL_NOTES = 'get_internal_notes',
    CREATE_INTERNAL_NOTE = 'create_internal_note',
    SET_NOTES_LOADING = 'set_notes_loading',
    SET_CREATE_NOTE_LOADING = 'set_create_note_loading',
    SET_CREATE_NOTE_ERROR = 'set_create_note_error',
    CLEAR_INTERNAL_NOTES = 'clear_internal_notes',
}

export enum AdminActionType {
    CREATE_PARTNER = 'create_partner',
}

export enum CountriesActionType {
    ADD_COUNTRIES = 'add_countries',
}

export enum AvailableLanguagesActionType {
    AVAILABLE_LANGUAGES_LOADING = 'available_languages_loading',
    GET_AVAILABLE_LANGUAGES = 'get_available_languages',
    AVAILABLE_LANGUAGES_ERROR = 'available_languages_error',
    UPDATE_DEFAULT_LANGUAGE = 'update_available_language',
    CLEAR_DEFAULT_LANGUAGE_FLASH_MESSAGE = 'clear_default_language-flash_message',
    UPDATE_REPORTING_CHANNEL_LANGUAGE = 'update_reporting_channel_language',
}

export enum AutoReplyActionType {
    AUTO_REPLY_LOADING = 'auto_reply_loading',
    CREATE_AUTO_REPLY = 'create_auto_reply',
    GET_AUTO_REPLY = 'show_auto_reply',
    UPDATE_AUTO_REPLY = 'update_auto_reply',
    AUTO_REPLY_ERROR = 'auto_reply_error',
    CLEAR_AUTO_REPLY_ERROR_MESSAGE = 'clear_auto_reply_error_message',
}

export enum CategoryActionType {
    GET_CATEGORIES = 'get_categories',
}

export enum ClientActionType {
    GET_COMPANY_INFORMATION = 'get_company_information',
    SET_CLIENT_LOADING = 'set_client_loading',
    SET_CLIENT_ERROR = 'set_client_error',
    SET_CLIENT_LANGUAGE = 'set_client_language',
}

export enum LineOfContactActionType {
    ADD_LINE_OF_CONTACT = 'add_line_of_contact',
    SHOW_ADD_LINE_OF_CONTACT_ERRORS = 'add_line_of_contact_errors',
    CLEAR_ADD_LINE_OF_CONTACT_ERRORS = 'clear_add_line_of_contact_errors',
    GET_LINE_OF_CONTACTS = 'get_line_of_contacts',
    SET_LINE_OF_CONTACTS_LOADING = 'set_line_of_contacts_loading',
    SET_LINE_OF_CONTACT_UPDATE_PRIORITY_LOADING = 'set_line_of_contact_update_priority_loading',
    ADD_PRIORITY_OF_CONTACTS = 'add_priority_of_contacts',
    ADD_CONTACT = 'add_contact',
    SHOW_ADD_CONTACT_ERRORS = 'add_contact_errors',
    CLEAR_ADD_CONTACT_ERRORS = 'clear_add_contact_errors',
    UPDATE_CONTACT = 'update_contact',
    UPDATE_CONTACT_LIST_IN_STATE = 'update_contact_list_in_state',
    SHOW_UPDATE_CONTACT_ERRORS = 'update_contact_errors',
    CLEAR_UPDATE_CONTACT_ERRORS = 'clear_update_contact_errors',
    DELETE_CONTACT = 'delete_contact',
    SHOW_DELETE_CONTACT_ERRORS = 'delete_contact_errors',
    CLEAR_DELETE_CONTACT_ERRORS = 'clear_delete_contact_errors',
    ADD_SELF = 'add_self',
    SHOW_ADD_SELF_ERRORS = 'add_self_errors',
    CLEAR_ADD_SELF_ERRORS = 'clear_add_self_errors',
    SHOW_FLASH_MESSAGE = 'show_flash_message',
    LOC_CLEAR_FLASH_MESSAGE = 'loc_clear_flash_message',
    SET_LINE_OF_CONTACT_DELETE_LOADING = 'set_line_of_contact_delete_loading',
    SET_LINE_OF_CONTACT_UPDATE_LOADING = 'set_line_of_contact_update_loading',
    GET_IS_EXCLUDE_MODE = 'get_is_exclude_mode',
    RESET_LINE_OF_CONTACT_LOADING = 'reset_line_of_contact_loading',
}

export enum SpokenLanguagesActionType {
    SPOKEN_LANGUAGE_LOADING = 'spoken_language_loading',
    GET_SPOKEN_LANGUAGES = 'get_spoken_languages',
    DELETE_SPOKEN_LANGUAGE = 'delete_spoken_language',
}

export enum ReportQuestionActionType {
    ADD_REPORT_QUESTION = 'add_report_question',
    GET_REPORT_QUESTIONS = 'get_report_questions',
    PUT_REPORT_QUESTION = 'put_report_question',
    DELETE_REPORT_QUESTION = 'delete_report_question',
    SET_REPORT_QUESTION_LOADING = 'set_report_question_loading',
}

export enum NotificationActionType {
    GET_NOTIFICATIONS = 'get_notifications',
    SET_GET_NOTIFICATIONS_NEXT_PAGE_LOADING = 'set_get_notifications_next_page_loading',
    GET_NOTIFICATIONS_NEXT_PAGE = 'get_notifications_next_page',
    SHOW_LATEST_NOTIFICATION = 'show_latest_notification',
    READ_ALL_NOTIFICATIONS = 'read_all_notifications',
    READ_SINGLE_NOTIFICATION = 'read_single_notification',
    FILTER_NOTIFICATIONS = 'filter_notifications',
    RESET_NOTIFICATION_COUNT = 'reset_ntification_count',
}

export enum CaseworkerOpenReportActionType {
    SET_GET_SINGLE_REPORT_LOADING = 'set_get_single_report_loading',
    SET_GET_SINGLE_REPORT_ERROR = 'set_get_single_report_error',
    GET_SINGLE_REPORT = 'get_single_report',
    SET_GET_REPORT_MESSAGES_LOADING = 'set_get_report_messages_loading',
    SET_GET_REPORT_MESSAGES_ERROR = 'set_get_report_error',
    GET_REPORT_MESSAGES = 'get_report_messages',
    SET_ADD_MESSAGE_LOADING = 'set_add_message_loading',
    ADD_MESSAGE = 'add_message',
    SHOW_LATEST_MESSAGE = 'show_latest_message',
    SHOW_LATEST_FILES = 'show_latest_files',
    CASEWORKER_DOWNLOAD_FILE = 'caseworker_download_file',
    SET_CHANGE_RESPONSIBLE_LOADING = 'set_change_responsible_loading',
    CHANGE_RESPONSIBLE = 'change_responsible',
    SET_UPDATE_CATEGORY_LOADING = 'set_update_category_loading',
    UPDATE_CATEGORY = 'update_category',
    UPDATE_SELECTED_CASEWORKER = 'update_selected_caseworker',
    SET_FORWARD_REPORT_LOADING = 'set_forward_report_loading',
    SET_FORWARD_REPORT_ERROR = 'set_forward_report_error',
    FORWARD_REPORT = 'forward_report',
    SET_UPDATE_STATUS_LOADING = 'set_update_status_loading',
    UPDATE_STATUS = 'update_status',
    SET_CLOSE_REPORT_LOADING = 'set_close_report_loading',
    CLOSE_REPORT = 'close_report',
    LEAVE_REPORT = 'leave_report',
    SHOW_SPECIAL_FORWARDED_MESSAGE = 'show_special_forwarded_message',

    REOPEN_REPORT = 'reopen_report',
    SET_REOPEN_REPORT_LOADING = 'set_reopen_report_loading',
    SET_REOPEN_REPORT_ERROR = 'set_reopen_report_error',
}

export enum CaseStatisticActionType {
    SET_GET_DASHBOARD_CASE_STATISTICS_LOADING = 'set_get_dashboard_case_statistics_loading',
    GET_DASHBOARD_CASE_STATISTICS = 'get_dashboard_case_statistics',
}

export enum ResetPasswordActionType {
    SEND_PASSWORD_RESET_LINK = 'send_password_reset_link',
    RESET_PASSWORD = 'reset_password',
    SET_RESET_PASSWORD_LOADING = 'set_reset_password_loading',
    SET_RESET_PASSWORD_ERROR = 'set_reset_password_error',
    CLEAR_RESET_PASSWORD_SUCCESS_MESSAGE = 'clear_reset_password_success_message',
    CLEAR_RESET_PASSWORD_ERROR = ' clear_reset_password_error',
}

export enum CustomDesignActionType {
    SET_CUSTOM_DESIGN_LOADING = 'set_custom_design_loading',
    RESTORE_CUSTOM_DESIGN = 'restore_custom_design',
    POST_CUSTOM_DESIGN = 'post_custom_design',
}

export enum PaymentActionType {
    CREATE_SETUP_INTENT = 'create_setup_intent',
    GET_PAYMENT_INFORMATION = 'get_payment_information,',
    ADD_PAYMENT_METHOD = 'add_payment_method',
    SET_GET_PAYMENT_INFORMATION_LOADING = 'set_get_payment_information_loading',
    SET_PAYMENT_METHOD_BEING_MADE_DEFAULT_LOADING = 'set_payment_method_being_made_default_loading',
    SET_PAYMENT_METHOD_BEING_DELETED_LOADING = 'set_payment_method_being_delete_loading',
    SET_UPDATE_PAYMENT_METHOD_LOADING = 'set_update_payment_method_loading',
    SET_ADD_PAYMENT_METHOD_LOADING = 'set_add_payment_method_loading',
    SET_MANUAL_CHARGE_LOADING = 'set_manual_charge_loading',
    UPDATE_DEFAULT_PAYMENT_METHOD = 'update_default_payment_method',
    DELETE_PAYMENT_METHOD = 'delete_payment_method',
    MANUAL_CHARGE = 'manual_charge',
    CLEAR_FLASH_MESSAGE = 'clear_flash_message',
    SET_GET_PAYMENT_METHOD_ERROR = 'set_get_payment_method_error',
    SET_ADD_PAYMENT_METHOD_ERROR = 'set_add_payment_method_error',
    SET_UPDATE_PAYMENT_METHOD_ERROR = 'set_update_payment_method_error',
    SET_DELETE_PAYMENT_METHOD_ERROR = 'set_delete_payment_method_error',
    SET_MANUAL_CHARGE_ERROR = 'set_manual_charge_error',
    CLEAR_ADD_PAYMENT_METHOD_ERROR = 'clear_add_payment_mehod_error',
}

export enum OrganizationActionType {
    GET_ORGANIZATION_INFORMATION = 'get_organization_information',
}

export enum IntegrationActionType {
    GET_INTEGRATION_DATA = 'get_integration_data',
    SET_FLASH_MESSAGE = 'set_flash_message',
    CLEAR_FLASH_MESSAGE = 'clear_flash_message',
    APPROVE_INTEGRATION = 'approve_integration',
}

export enum InviteCaseworkerActionType {
    INVITE_CASEWORKER_LOADING = 'invite_caseworker_loading',
    INVITE_CASEWORKER_ERROR = 'invite_caseworker_error',
    INVITE_CASEWORKER_SUCCESS = 'invite_caseworker_success',
    INVITE_CASEWORKER_FLASH_MESSAGE = 'invite_caseworker_flash_message',
    INVITE_CASEWORKER_CLEAR_FLASH_MESSAGE = 'invite_caseworker_clear_flash_message',
}

export enum CaseworkerOnboardingActionType {
    ONBOARDING_LOADING = 'onboarding_loading',
    ONBOARDING_ERROR = 'onboarding_error',
    ONBOARDING_SUCCESS = 'onboarding_success',
    PERSONAL_INFORMATION_REQUEST = 'personal_information_request',
    PERSONAL_INFORMATION_LOADING = 'personal_information_loading',
    PERSONAL_INFORMATION_ERROR = 'personal_information_error',
    PERSONAL_INFORMATION_SUCCESS = 'personal_information_success',
    ADDITIONAL_INFORMATION_REQUEST = 'additional_information_request',
    ADDITIONAL_INFORMATION_LOADING = 'additional_information_loading',
    ADDITIONAL_INFORMATION_ERROR = 'additional_information_error',
    ADDITIONAL_INFORMATION_SUCCESS = 'additional_information_success',
}

export enum TranslateTextActionType {
    TRANSLATION_LOADING = 'translation_loading',
    TRANSLATION_ERROR = 'translation_error',
    TRANSLATION_SUCCESS = 'translation_success',
}

export enum TranslateDescriptionActionType {
    TRANSLATION_LOADING = 'translate_description_loading',
    TRANSLATION_ERROR = 'translate_description_error',
    TRANSLATION_SUCCESS = 'translate_description_success',
}

export enum WhitelabelActionType {
    GET_WHITELABEL = 'get_whitelabel',
}

export enum StatisticsOverviewActionType {
    STATISTICS_OVERVIEW_REQUEST = 'statistics_overview_request',
    STATISTICS_OVERVIEW_SUCCESS = 'statistics_overview_success',
    STATISTICS_OVERVIEW_ERROR = 'statistics_overview_error',
}

export enum DeletedCaseActionType {
    SHOW_DELETED_INCIDENT = 'show_deleted_incident',
}
